import {openModal, slEvent} from './utils'

const cancelSubscription = '/account/billing/subscription/cancel/';
const uncancelSubscription = '/account/billing/subscription/uncancel/';
let referralCheck = null;

const elementsStyle = {
  base: {
    fontSize: '16px',
    fontFamily: "'Montserrat', sans-serif",
    color: "#32325d",
  }
}

const elementsOptions = {
  fonts: [{
    cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i'
  }]
}

function buttonsDisable() {
  const $button = $("#confirm-cart");
  $button.attr("disabled", true);
  $button.html("Submitting... <i class='fa fa-spinner fa-spin'></i>");
  $('.alert-block').remove();
}

function buttonsEnable(text) {
  const $button = $("#confirm-cart");
  $button.attr("disabled", false);
  $button.text(text);
}

function showError(message) {
  $(".payment-errors").removeClass('hide');
  $(".payment-errors").text(message);
  buttonsEnable('Confirm Order!');
}



function discountTextClear() {
  $('.discount-code-text').remove();
}

function discountText(errorType) {
  discountTextClear();
  var discountErrorHTML =         '<div class="discount-code-text text-error" style="margin-top: 10px;"><i class="fa fa-times"></i> Discount Code Not Valid</div>'
  var discountSuccessHTML =       '<div class="discount-code-text text-success" style="margin-top: 10px;"><i class="fa fa-check"></i> Discount Code Valid</div>'
  switch(errorType) {
    case 'success':
      $('#id_referral_code').parent().append(discountSuccessHTML);
      break
    case 'error':
      $('#id_referral_code').parent().append(discountErrorHTML);
      break
    default:
      break
  }
}

function confirmCard(stripe, card, token=null) {
  buttonsDisable();
  // event.preventDefault();
  if (token){
    return Promise.resolve({'stripe_token':token})
  }
  var selected_card = $('.paymentMethod.active').data('id')
  if (selected_card) {
    return Promise.resolve({'stripe_card_id':selected_card})
  }
  return stripe.createToken(card).then(function(result) {
    if (result.error) {
      buttonsEnable('Confirm Order!')
      throw result.error;
    } else {
      return {'stripe_token':result.token.id}

    }
  });
}

function cartDiscountText(obj) {
  discountTextClear();
  var discountErrorHTML   = '<div class="discount-code-text text-error"><i class="fa fa-times"></i> Discount Code Not Valid</div>'
  var discountSuccessHTML = '<div class="discount-code-text text-success"><i class="fa fa-check"></i> Discount Code Valid</div>'
  if ($('#referral_code').val()==0){
    $('.discount-code-text').remove();
  } else{
    if (obj.error_message) {
      return ['error', '<div class="discount-code-text text-error"><i class="fa fa-check"></i> ' + obj.error_message + '</div>'];
    } else if (Object.keys(obj).length === 0){
      return ['error', discountErrorHTML];
    }
    else{
      return ['success',discountSuccessHTML];
    }
  }
  if ($('#referral_code_biz').val()==0){
    $('.discount-code-text').remove();
  }
  else{
    if (Object.keys(obj).length === 0){
      return ['error', discountErrorHTML];
    }
    else{
      return ['success',discountSuccessHTML];
    }
  }
}

function removeCheckCodeBtn() {
  $('#discount-code-check').remove();
}

function checkCodeAnimate(action) {
  var btnIcon = $('#discount-code-check i');
  btnIcon.removeClass('fa-spin');

  switch(action) {
    case 'start':
      btnIcon.addClass('fa-spin')
      break
    case 'stop':
      btnIcon.removeClass('fa-spin')
      break
    default:
      break
  }
}

function hideShowPromo() {
  /* Always show promo for now */
  if (true || subscriptionOption.val() === 'sl-yearly-850') {
    $('#div_id_referral_code').show()
  } else {
    $('#div_id_referral_code').hide()
  }
}

function submitForm() {
  var $button = $("input[type=submit]")
  $button.data('text', $button.val())
  buttonsDisable();
  if ($("#id_card_number").is(":visible")) {
    var form = this;
    var card = {
      number:   $("#id_card_number").val(),
      expMonth: $("#id_card_expiry_month").val(),
      expYear:  $("#id_card_expiry_year").val(),
      cvc:      $("#id_card_cvv").val()
    };
    if (!card.number) {
      showError('Card Number is required')
      return false;
    }
    if (!card.cvc) {
      showError('CVC is required')
      return false;
    }

    Stripe.card.createToken(card, function(status, response) {
      if (status === 200) {
        $(".payment-errors").addClass('hide');
        $("#id_last_4_digits").val(response.card.last4);
        $("#id_stripe_token").val(response.id);
        form.submit();
      } else {
        showError(response.error.message);
      }
    });
    return false;
  } else {
    buttonsDisable()
    return true;
  }
}

window.paymentMethodInit = function(key, nextUrl) {
  const $button = $('.add-card')
  const displayError = $('#card-errors');
  const stripe = Stripe(key);
  const elements = stripe.elements(elementsOptions)
  const card = elements.create('card', {
    style: elementsStyle
  })
  function buttonState(on) {
    $button.attr('disabled', !on)
  }
  card.mount('#card-element');
  card.addEventListener('change', function(event) {
    if (event.error) {
      displayError.text(event.error.message);
      buttonState(false)
    } else {
      displayError.text('')
      buttonState(true)
    }
  });
  buttonState(false)

  $button.on('click', async () => {
    buttonState(false)
    const result = await stripe.createToken(card)
    if (result.error) {
      displayError.text(result.error.message)
      buttonState(true)
    } else {
      $.ajax({
        url: '/api/v1/paymentmethod/add/',
        data: {
          'stripe_token': result.token.id
        },
        method: 'POST',
      }).done(function(response) {
        if (response.success) {
          if (nextUrl) {
            location.href = nextUrl;
          } else {
            location.href = '/account/billing/methods/';
          }
        } else {
          displayError.text(response.error)
          buttonState(true)
        }
      }).catch(function() {
        displayError.text('Unable to add card at this time')
        buttonState(true)
      });
    }
  })
}

window.pricingInit = function() {
  $('.premier-prices .price-toggle').change(function() {
    $('.premier-prices .price').addClass('hide')
    $('.premier-prices .price.' + $(this).val()).removeClass('hide')
    $('.btn-premier').addClass('hide')
    $('.btn-premier.' + $(this).val()).removeClass('hide')
  })
  $('.premier-prices .price-toggle').change()

  $('.plus-prices .price-toggle').change(function() {
    $('.plus-prices .price').addClass('hide')
    $('.plus-prices .price.' + $(this).val()).removeClass('hide')
    $('.btn-plus').addClass('hide')
    $('.btn-plus.' + $(this).val()).removeClass('hide')
  })
  $('.plus-prices .price-toggle').change()
}

window.cartInit = function(key, dc='', no_header=null, api_obj=null, valuation_id=null, bizCost=null) {
  var bizTotal = bizCost

  try {
    var stripe = Stripe(key);
  }
  catch(err) {
    $('.errors').html("Unable to connect to our payment processor. Please contact our support.")
    $('.errors').removeClass('hide')
    $('.card').remove()
    $('#or-enter-info').remove()
    return
  }

  $('input[name=membership_plan]').change(function() {
    location.href = '/account/cart/add/' + $(this).val()
  })

  $('.show-schedule-btn').click(function() {
    $(this).addClass('hide')
    $('.hide-schedule-btn').removeClass('hide')
    $('.payment-schedule').removeClass('hide')
  });

  $('.hide-schedule-btn').click(function() {
    $(this).addClass('hide')
    $('.show-schedule-btn').removeClass('hide')
    $('.payment-schedule').addClass('hide')
  });

  $('#chalice-join').submit(function(event) {
    event.preventDefault()
    $('#modal-confirm-chalice')[0].M_Modal.options.dismissible = false;
    $('.join-chalice-confirm').val("Submitting...");
    $('.join-chalice-confirm').prop("disabled", true);
    var obj = {api_obj: api_obj}
    $.ajax({
      method: 'POST',
      url: '/api/v1/chalice/upgrade/confirmation/',
      data: obj
    }).done(function(results) {
      $('#modal-header').text('Check Your Email')
      $('#billing-info').addClass('hide')
      $('#validate-content').removeClass('hide')
    }).fail(function(xhr, status, error) {
      $('.join-chalice-confirm').val("Confirm Purchase");
      $('.join-chalice-confirm').prop("disabled", false);
      $('.errors').removeClass('hide')
      $('.errors').html(xhr.responseJSON.error)
      })
  });

  let elements = stripe.elements(elementsOptions)
  var card
  var discount_amount = 0
  var referralCode = ''
  var created = false
  var current_coupon_status = 'error'

  $('#defer-payment-input').change(function() {
    reloadCart(discount_amount, referralCode)
  })

  function reloadCart(discount_amount, referralCode, coupon_status=null) {
    $.ajax({
      method: 'GET',
      url: '/api/v1/cart/',
      data: {
        'discount_amount':discount_amount,
        'referral_code':referralCode,
        'free_trials_offered':JSON.stringify(freeTrials)},
      contentType: 'application/json',
    }).done(function(obj) {
      $('#cart').html(obj['html'])
      if ($('#defer-payment-input').is(":checked")) {
        var total_charges = $('.total-charges');
        total_charges.html('$' + "0.00");
      }
      // if (obj['subscription_is_free_trial']){
      //   $('#confirm-cart').text('Start Free Trial');
      // }
      setupCart(coupon_status)
    })
  }

  function reloadMethods(no_header) {
    $.ajax({url: '/api/v1/paymentmethod/',
            data: {
              'no_header':no_header
            }
          }).done(function(results) {
        $('#paymentmethod').html(results)
        setupMethods()
    })
  }

  function showLoading(selector) {
      $(selector).html("<div class='text-center' style='padding: 20px'><h6><i class='fa fa-spinner fa-spin'></i> Loading...</h6></div>")
  }

  function setupMethods() {
    $('.paymentMethod').click(function(e, i) {
       $('.paymentMethod').removeClass('active')
        $.ajax({
            method: 'POST',
            url: '/api/v1/paymentmethod/select/' + $(this).data('id') + '/'
        }).done(function(results) {
            reloadMethods(no_header);
            $('.errors').addClass('hide')
        }).fail(function(xhr, status, error) {
            $('.errors').removeClass('hide')
            $('.errors').html("Unable to select card. Please try again later.")
        })
    })
    $('#add-card-checkbox').click(function(){
      $('.paymentMethod').removeClass('active')
      $('#add-card').css('display', 'block');
      $('.far').removeClass('fa-check-square')
      $('.far').addClass('fa-square')
      $('#add-box-icon').removeClass('fa-square')
      $('#add-box-icon').addClass('fa-check-square')
      card = null
      elements = stripe.elements(elementsOptions);
      setUpCard()
    })
    if ($('.paymentMethod').length == 0){
      setUpCard()
    }
  }

  function createPayNow() {
    var cartTotal = Number($('#cart-total').text().replace(/[^0-9.-]+/g,""))*100;
    var paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Cart Total',
        amount: cartTotal,
      },
      requestShipping: false,
      requestPayerName: false,
      requestPayerEmail: false,
    });
    var prButton = elements.create('paymentRequestButton', {
      style: elementsStyle,
      paymentRequest: paymentRequest,
    });
    prButton.on('click', function(ev) {
      var cartTotal = Number($('#cart-total').text().replace(/[^0-9.-]+/g,""))*100;
      paymentRequest.update({
        total: {
          label: 'Order Total',
          amount: cartTotal,
        }
      })
    })
    paymentRequest.canMakePayment().then(function(result) {
      const paymentGroup = document.getElementById('payment-request-group')
      if (paymentGroup) {
        if (result) {
          paymentGroup.style.display = 'block';
          prButton.mount('#payment-request-button');
        } else {
          paymentGroup.style.display = 'none';
        }
      }
    });
    paymentRequest.on('token', function(ev) {
      confirmOrder(ev.token.id, function(success) {
        if (success) {
          slEvent('paynow-success', {})
          ev.complete('success');
        } else {
          slEvent('paynow-failure', {})
          ev.complete('fail');
        }
      })
    });
  }

  function setupCart(coupon_status=null) {
    const cartDetails = $('.cart-details')
    if (dc){
      $('#referral_code').val(dc)
      dc = null
      applyReferralCode(coupon_status)
    }
    $('.sopt-info').click(function() {
      var productDetailsClass = "." + $(this).data('product-id') + "-details"
      $('.product-details-display').hide();
      $(productDetailsClass).show()
      openModal('#modal-show-premier-details')
    })
    $('.join-chalice').click(function() {
      openModal('#modal-confirm-chalice')
    })

    $('.cartEdit').unbind('change');
    $('.cartEdit').change(function(e, i) {
        showLoading('#cart')
        var productId = $(this).data('product-id')
        $.ajax({
            method: 'POST',
            url: '/api/v1/cart/' + productId + '/' + $(this).val() + '/'
        }).done(function(results) {
            reloadCart(discount_amount)
        })
    })
    $('.cartDelete').unbind('click');
    $('.cartDelete').click(function(e, i) {
        showLoading('#cart')
        var productId = $(this).data('product-id')
        $.ajax({
            method: 'DELETE',
            url: '/api/v1/cart/' + productId + '/'
        }).done(function(results) {
            reloadCart(discount_amount, referralCode)
        })
    })
    $('#apply-coupon').unbind('click');
    $('#apply-coupon').click(function() {
      applyReferralCode(coupon_status)
    })
    $('#referral_code').unbind('keyup');
    $('#referral_code').keyup(function() {
      applyReferralCode(coupon_status)
    })
  }

  function setUpCard() {
    card = elements.create('card', {
      style: elementsStyle
    });
    card.mount('#card-element');
    card.addEventListener('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  function confirmOrder(token=null, callback=null) {
    $('.errors').addClass('hide')
    confirmCard(stripe, card, token).then(function(obj) {
      var $b = $(this)
      var $applyCoupon = $('#apply-coupon')
      var $addProducts = $('#add-products')
      var origText = $b.text()
      obj['discount_amount'] = discount_amount
      obj['referral_code'] = $('#referral_code').val()
      obj['defer_payment'] = $('#defer-payment-input').is(":checked")
      $b.addClass('disable')
      $applyCoupon.attr("disabled", true);
      $addProducts.attr("disabled", true);
      $b.text('Please wait...')
      $.ajax({
        method: 'POST',
        url: '/api/v1/order/confirmation/',
        data: obj
      }).done(function(results) {
        location.href = '/account/billing/complete/'
        if (callback) {
          callback(true)
        }
      }).fail(function(xhr, status, error) {
        buttonsEnable('Confirm Order!')
        $b.text(origText)
        $b.removeClass('disable')
        $applyCoupon.attr("disabled", false);
        $addProducts.attr("disabled", false);
        $('.errors').removeClass('hide')
        $('.errors').html(xhr.responseJSON.error)
        if (callback) {
          callback(false)
        }
      })
    }).catch(function(result){
      if (callback) {
        callback(false)
      }
    })
  }
  $('.confirmOrder').click(function() {
    confirmOrder(null)
  })

  function checkReferralCode(referral_code) {
    if (referral_code) {
      referral_code = referral_code.toUpperCase();
      $('#id_referral_code').val(referral_code);
      $('#referral_code').val(referral_code);
    }
    var subscription_id = ''
    var $total_charges = $('.total-charges');

    var discountLabel = '<label class="pull-left label label-info"">' + referral_code + ' DISCOUNT</label>';
    var requestData = {'coupon_id': referral_code, 'is_free_trial': false}
    return $.ajax({
      type: 'GET',
      url: '/account/billing/coupon/validate/',
      data: requestData,
      success: function(response){
        if (response['coupon'] && response['coupon']['valid']) {
          var percent_off = response['coupon']['percent_off']
          var amount_off = response['coupon']['amount_off']
          var subscription_id = response['subscription_id']
          var subscription_charges = response['subscription_charges']
          var total_charges = response['total_charges']
          if (($('#defer-payment-input').is(":checked"))) {
            total_charges = 0
          }
          discount_amount = 0
          if (amount_off) {
            amount_off = amount_off * 0.01;
            discount_amount = amount_off;
          } else {
            discount_amount = (subscription_charges * percent_off)/100;
          }
        } else {
          discount_amount = 0
          $('.row-discount').remove();
          if (response['error_code']){
            discountText(response['error_code']);
          } else {
            discountText('error');
          }
        }
        checkCodeAnimate('stop');
      }
    });
  }

  function applyReferralCode(coupon_status){
    if (referralCheck != null) {
      referralCheck.abort()
    }
    referralCheck = checkReferralCode($('#referral_code').val())
    referralCheck.then(function(obj){
      coupon_status = cartDiscountText(obj)
      $('#referral_code').parent().append(coupon_status[1])
      if(coupon_status[0]!=current_coupon_status){
        current_coupon_status = coupon_status[0]
        reloadCart(discount_amount, referralCode, coupon_status[1])
      }
    })
  }
  showLoading('#cart')
  reloadCart(discount_amount, referralCode)
  showLoading('#paymentmethod')
  reloadMethods(no_header)
  createPayNow()


  $('#full-valuation').click(function(event) {
    $('#full-valuation').html("Processing... <i class='fa fa-spinner fa-spin'></i>");
    confirmCard(stripe, card, null).then(function(obj) {
      event.preventDefault()
      obj['valuation_id'] = valuation_id
      obj['coupon_id'] = $('#referral_code_biz').val()
      $.ajax({
        method: 'POST',
        url: '/api/v1/business/valuation/purchase/',
        data: obj
      }).done(function(obj) {
        window.open(obj['url'],'_blank')
        $('#full-report-url').attr('href', obj['url'])
        $('.original-content').addClass('hide')
        $('.purchaged-content').removeClass('hide')
      }).fail(function(xhr, status, error) {
        $('#full-valuation').html("Purchase Results");
        $('.join-chalice-confirm').prop("disabled", false);
        $('.errors').removeClass('hide')
        $('.errors').html(xhr.responseJSON.error)
        })
    }).catch(function(obj){
      $('#full-valuation').html("Purchase Results");
    })
  })

  function checkReferralCodeBiz(referral_code) {
    if (referral_code) {
      referral_code = referral_code.toUpperCase();
      $('#id_referral_code_biz').val(referral_code);
      $('#referral_code_biz').val(referral_code);
    }
    var subscription_id = ''
    var $total_charges = $('.total-charges');

    var discountLabel = '<label class="pull-left label label-info"">' + referral_code + ' DISCOUNT</label>';
    var requestData = {'coupon_id': referral_code, 'is_free_trial': false, 'biz_equity': 1}
    return $.ajax({
      type: 'GET',
      url: '/account/billing/coupon/validate/',
      data: requestData,
      success: function(response){
        if (response['coupon'] && response['coupon']['valid']) {
          var percent_off = response['coupon']['percent_off']
          var amount_off = response['coupon']['amount_off']
          var subscription_id = response['subscription_id']
          var subscription_charges = response['subscription_charges']
          var total_charges = response['total_charges']
          discount_amount = 0
          if (amount_off) {
            amount_off = amount_off * 0.01;
            discount_amount = amount_off;
          } else {
            discount_amount = (subscription_charges * percent_off)/100;
          }
        } else {
          discount_amount = 0
          $('.row-discount').remove();

          discountTextBiz('error');
        }
        checkCodeAnimate('stop');
      }
    });
  }

  function applyBizReferralCode(coupon_status){
    if (referralCheck != null) {
      referralCheck.abort()
    }
    referralCheck = checkReferralCodeBiz($('#referral_code_biz').val())
    referralCheck.then(function(obj){
      coupon_status = cartDiscountText(obj)
      $('#referral_code_biz').parent().append(coupon_status[1])
      if (coupon_status[0] != current_coupon_status) {
        let discount = 0
        const coupon = referralCheck.responseJSON.coupon
        if (coupon.percent_off) {
          discount = bizCost * coupon.percent_off / 100.0
        } else {
          discount = coupon.amount_off
        }
        bizTotal = bizCost - discount
        $('#subtotal').removeClass('hide')
        $('#discount').removeClass('hide')
        $('#subtotal-amount').text('$' + parseFloat(bizCost * 0.01).toFixed(2))
        $('#discount-amount').text('-$' + parseFloat(discount * 0.01).toFixed(2))
        $('#total-amount').text('$' + parseFloat((bizTotal * 0.01)).toFixed(2))
      } else {
        bizTotal = bizCost
        $('#subtotal').addClass('hide')
        $('#discount').addClass('hide')
        $('#total-amount').text('$' + parseFloat(bizCost * 0.01).toFixed(2))
      }
    })
  }

  function discountTextBiz(errorType) {
    discountTextClear();
    var discountErrorHTML = '<div class="discount-code-text text-error" style="margin-top: 10px;"><i class="fa fa-times"></i> Discount Code Not Valid</div>'
    var discountSuccessHTML = '<div class="discount-code-text text-success" style="margin-top: 10px;"><i class="fa fa-check"></i> Discount Code Valid</div>'
    $('')
    switch(errorType) {
      case 'success':
        $('#referral_code_biz_parent').append(discountSuccessHTML);
        break
      case 'error':
        $('#referral_code_biz_parent').append(discountErrorHTML);
        break
      default:
        break
    }
  }

  $('#apply-coupon-biz').unbind('click');
  $('#apply-coupon-biz').click(function() {
    checkReferralCodeBiz(coupon_status=null)
  })
  var coupon_status = null
  $('#referral_code_biz').unbind('keyup');
  $('#referral_code_biz').keyup(function() {
    applyBizReferralCode(coupon_status)
  })
  $('#referral_code_biz').blur(function() {
    if ($('#referral_code_biz').val()=='') {
      discountTextClear()
    }
  })
}

window.billingInit = function(discountCode, admin=false) {
  var subscriptionOption = $('#id_subscription_option');
  var paymentMethods = $('#id_payment_methods');
  var referralCode = $('#id_referral_code');
  var account_balance = 0
  var upgrade_cost = 0
  var amount_refundable = 0
  var plan_name = ''
  var plan_amount = 0
  var setup_fee = 0
  var discount_value = 0
  function formatNum(num) {
    return num.toLocaleString(
      undefined, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
  }
  $('form').submit(function() {
    $('#submit-payment-button').html("Submitting... <i class='fa fa-spinner fa-spin'></i>");
    $('#submit-payment-button').prop("disabled", true);
  })
  if (admin == true) {
    $('#div_id_subscription_option').addClass('hide')
  }
  // figure out what invoice subscription should be based on the user option selction
  function getInvoiceSubscription() {
    var subscription = upgrade_cost;
    if (plan_amount > upgrade_cost) {
      $('#prorated-info').removeClass('hide')
      $('.prorate-credit').text("Credit for previous subscription")
      $('.prorate-credit').addClass('success-green')
      $('.subscription-prorated').text(formatNum(amount_refundable))
      $('.subscription-prorated').addClass('success-green')
    } else {
      $('#prorated-info').addClass('hide')
    }
    if (account_balance > 0) {
      $('#remaining-credit').removeClass('hide')
      $('#remaining-credit').removeClass('success-green')
      $('#remaining-credit').addClass('danger-red')
      $('.remaining-credit-amount').text(formatNum(account_balance))
    } else if (account_balance < 0) {
      $('#remaining-credit').removeClass('hide')
      $('#remaining-credit').removeClass('danger-red')
      $('#remaining-credit').addClass('success-green')
      $('.remaining-credit-amount').text(formatNum(account_balance))
    }
    return subscription.toFixed(2);
  }

  // calculate the total for display on a buyer's invoice
  function getInvoiceTotal(){
    var total = 0;
    total = Math.max(upgrade_cost + account_balance - discount_value, 0)
    return total.toFixed(2);
  }

  // get the invoice data and inject into html invoice
  function loadInvoiceData(){
    var $subscription_option = $('.subscription-option');
    var $subscription_fee = $('.subscription-fee');
    var $setup_fee = $('.setup-fee');
    var $total_charges = $('.total-charges');
    var subscription_cost = getInvoiceSubscription(true)
    $setup_fee.html('<span style="color: green;">' + formatNum(setup_fee) + '</span>');
    $subscription_fee.html('<span>' + formatNum(plan_amount) + '</span>');
    $subscription_option.html('(<strong>' + plan_name + '</strong>)');
    $('#submit-payment-button').html('Submit Payment');
  }

  function checkReferralCode(referral_code) {
    if (referral_code) {
      referral_code = referral_code.toUpperCase();
      $('#id_referral_code').val(referral_code);
    }

    var $total_charges = $('.total-charges');
    var requestData = 'coupon_id=' + referral_code + '&payment_plan=' + $('#id_subscription_option').val();
    $.ajax({
      type: 'GET',
      url: '/account/billing/coupon/?' + requestData,
      success: function(response){
        if (response && response['valid']) {
          var percent_off = response['percent_off']
          var amount_off = response['amount_off']
          var total_charges = getInvoiceSubscription(false);
          if (amount_off) {
            amount_off = amount_off * 0.01;
            discount_value = (total_charges - amount_off);
          } else {
            discount_value = (total_charges * percent_off)/100;
          }

          $('#discount-row').removeClass('hide');
          $('.discount-code-amount').text(formatNum(discount_value))
          $total_charges.html('$' + getInvoiceTotal());
          discountText('success');
        } else {
          $('#discount-row').addClass('hide');
          discountText('error');
          $total_charges.html('$' + getInvoiceTotal());
          if(referral_code === '' || typeof referral_code === 'undefined'){
            discountTextClear();
          }
        }
        checkCodeAnimate('stop');
      }
    });
  }
  paymentMethods.change(function() {
    if ($(this).val() === 'new-payment-method') {
      location.href = '/account/billing/methods/add?next=/account/billing/';
    }
  })
  subscriptionOption.change(function(){
    $('#discount-row').addClass('hide');
    $.ajax({
      type: 'GET',
      url: '/api/v1/prorated/?plan_id=' + $('#id_subscription_option').val(),
      success: function(response){
        account_balance = response.account_balance
        upgrade_cost = response.upgrade_cost
        amount_refundable = response.amount_refundable
        plan_name = response.plan_name
        plan_amount = response.plan_amount
        setup_fee = response.setup_fee

        discountTextClear();
        loadInvoiceData();
        hideShowPromo();
        checkReferralCode(referralCode.val());

        $('.plan').addClass('hide')
        if (response.is_network_plan) {
          $('.plan-network').removeClass('hide')
          $('.premier-plan-amount').text('$' + plan_amount)
          $('.premier-plan-interval').text('per ' + response.interval)
        }
        if (response.is_premier_plan) {
          $('.plan-premier').removeClass('hide')
          $('.premier-plan-amount').text('$' + plan_amount)
          $('.premier-plan-interval').text('per ' + response.interval)
        }
        if (response.is_plus_plan) {
          $('.plan-plus').removeClass('hide')
          $('.plus-plan-amount').text('$' + plan_amount)
          $('.plus-plan-interval').text('per ' + response.interval)
        }
      }
    });
  });
  subscriptionOption.change()
  $('#discount-code-check').on('click', function() {
    checkCodeAnimate('start');
    checkReferralCode(referralCode.val());
  });
  referralCode.keyup(function() {
    checkReferralCode(referralCode.val())
  })

  $("#payment-form").submit(submitForm)

  if (discountCode) {
    $('#id_referral_code').val(discountCode);
    $('#referral_code').val(discountCode);
    checkCodeAnimate('start');
    checkReferralCode(discountCode);
  }
  $('#id_subscription_option').change()

  loadInvoiceData();
  hideShowPromo();
}

window.billingOverviewInit = function(discountCode) {
  $('#apply_discount').click(function() {
    var input = $('#referral_code');
    var error = $('#referral_code_error');
    var button = $(this);
    button.attr('disabled', true);
    error.addClass('hide');
    $.getJSON("/account/billing/discount/?coupon_id="+ input.val())
    .done(function(data) {
      location.href = "/account/billing/";
    }).fail(function(xhr, text, err) {
      var text = JSON.parse(xhr.responseText).error;
      error.text(text);
      error.removeClass('hide');
    }).always(function() {
      button.attr('disabled', false);
    });
  });

  var confirmedDowngrade = false;
  var confirmedCancel = false;
  $('.uncancel-autorenew').click(function() {
      var $button = $(this);
      if (confirmedCancel) {
        $button.text("...please wait...");
        $button.prop('disabled', true);
        $.ajax({
          url: uncancelSubscription,
          method: 'POST',
        }).done(function() {
          location.reload();
          $button.text = "Subscription will Renew!";
        });
      } else {
        $button.find('.confirm').addClass('hide');
        $button.find('.execute').removeClass('hide');
        $button.removeClass('btn-lg').removeClass('btn-success').addClass('btn-default');
        confirmedCancel = true;
      }
  });
  $('.cancel-autorenew').click(function() {
      var $button = $(this);
      if (confirmedCancel) {
        $button.text("Canceling renewal...");
        $button.prop('disabled', true);
        $.ajax({
          url: cancelSubscription,
          method: 'POST',
        }).done(function() {
          location.reload();
          $button.text = "Subscription Canceled!";
        });
      } else {
          $button.find('.confirm').addClass('hide');
          $button.find('.execute').removeClass('hide');
          $button.removeClass('btn-lg').removeClass('btn-success').addClass('btn-default');
          confirmedCancel = true;
      }
  });
  $('.cancel-nevermind').click(function() {
      confirmedCancel = false;
      $('.cancel-nevermind').addClass('hide');
      $('.are-you-sure').addClass('hide');
  });
  if (discountCode) {
    $('#referral_code').val(discountCode);
    // checkReferralCode(discountCode);
  }
}


window.cancelInit = function() {
  if ($('#id_id_reason_0_7').is(':checked')) {
    $('.hidden-other').css('display', 'block');
  }
  $('#id_id_reason_0_1').click(function() {
    $('.hidden-other').css('display', 'none');
  })
  $('#id_id_reason_0_2').click(function() {
    $('.hidden-other').css('display', 'none');
  })
  $('#id_id_reason_0_3').click(function() {
    $('.hidden-other').css('display', 'none');
  })
  $('#id_id_reason_0_4').click(function() {
    $('.hidden-other').css('display', 'none');
  })
  $('#id_id_reason_0_5').click(function() {
    $('.hidden-other').css('display', 'none');
  })
  $('#id_id_reason_0_6').click(function() {
    $('.hidden-other').css('display', 'none');
  })
  $('#id_id_reason_0_7').click(function() {
    $('.hidden-other').css('display', 'block');
  })
  $('form').submit(function() {
    $('#submit-id-action_save').prop("disabled", true);
  })
}

window.confirmInit = function() {
  $('#confirm-cancel-autorenew').click(function() {
    $('#confirm-cancel-autorenew').attr("disabled", true);
    $('#confirm-autorenew').attr("disabled", true);
    $('#uncancel-confirm-autorenew').attr("disabled", true);
    $('#confirm-cancel-autorenew').html("Please Wait... <i class='fa fa-spinner fa-spin'></i>");
    $('#do-not-cancel').attr("disabled", true);
  })
  $('#confirm-autorenew').click(function() {
    var button = $(this);
    button.text("Please Wait");
    button.attr('disabled', true);
    $('#confirm-cancel-autorenew').attr("disabled", true);
    $.getJSON("/account/billing/discount/renew/")
    .done(function(data) {
      location.href = "/account/billing/";
    }).fail(function(xhr, text, err) {
      button.attr('disabled', false);
    })
  });
  $('#uncancel-confirm-autorenew').click(function() {
    var data = {uncancel: true}
    var button = $(this);
    button.text("Please Wait");
    button.attr('disabled', true);
    $('#confirm-cancel-autorenew').attr("disabled", true);
    $.getJSON("/account/billing/discount/renew/", data)
    .done(function(data) {
      location.href = "/account/billing/apply/renew/discount/";
      M.Toast.dismissAll();
      M.toast(
        {html: 'Coupon has been applied to your next billing cycle'}
      );
    }).fail(function(xhr, text, err) {
      button.attr('disabled', false);
    })
  });
}

window.cancelDiscount = function(couponId) {
  $('#apply_discount').click(function() {
    var button = $(this);
    button.attr('disabled', true);
    $.getJSON("/account/billing/discount/?coupon_id=" + couponId).done(function(data) {
      location.href = "/account/billing/";
    }).fail(function(xhr, text, err) {
      var text = JSON.parse(xhr.responseText).error;
      M.Toast.dismissAll();
      M.toast(
        {html: text}
      );
    }).always(function() {
      button.attr('disabled', false);
    });
  });
}
