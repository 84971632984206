import {openModal} from './utils'

const FAIL_MESSAGE = 'Unable to change your settings at this time'

window.updateSettings = function(){
  $('#interests_form input[type=checkbox]').change(function() {
    const checkbox = $(this)
    var index = checkbox.index();
    $.ajax({
        type: "POST",
        url: "/api/v1/preferences/interests/",
        data: $('#interests_form').serialize(),
        dataType: 'json'
    }).fail(function() {
      M.Toast.dismissAll();
      M.toast({html: FAIL_MESSAGE})
    }).done(function() {
      M.Toast.dismissAll();
      if (checkbox.is(":checked")){
        M.toast({html: 'Subscribed to '+checkbox.attr("data-title")})
      } else {
        M.toast({html: 'Unsubscribed from '+checkbox.attr("data-title")})
      }
    })
  })
  $('input[type=radio]').click(function() {
    var radio = $(this).val();
    if (radio == 'on') {
      var msg = 'You will receive reminders for engagments you have not responded to.';
    } else if(radio == 'off') {
      var msg = 'You will not receive reminders for engagments you have not responded to.';
    } else if(radio == 'instant') {
      var msg = 'You will be receive emails for messages right away';
    } else if(radio == 'daily') {
      var msg = 'You will receive an email for new messages once per day.';
    } else if(radio == 'never') {
      var msg = 'You will no longer receive emails for new messages.';
    } else if(radio == 'True') {
      var msg = 'You will be able to reply to engagments from your email';
    } else if(radio == 'False') {
      var msg = 'You will not be able to reply to engagments from your email';
    }
    $.ajax({
      type: "POST",
      url: "/api/v1/preferences/email_freq/",
      data: JSON.stringify({
        'frequency': $(this).val(),
        'pk': $(this).attr('id'),
        'email-type': $(this).data('email-type')
      }),
      dataType: 'json'
    }).fail(function() {
      M.Toast.dismissAll();
      M.toast({html: FAIL_MESSAGE});
    }).done(function() {
      M.Toast.dismissAll();
      M.toast({html: msg})
    });
  });
}


window.initPreferences = function(inactive_filters, is_delinquent) {
  if (inactive_filters && is_delinquent){
    $('[id="freq"]').each(function(){
      if ($(this).val()!='never'){
        $("[data-active-id="+$(this).data('id')+"]").removeClass('deactivated-saved-search')
      }
      else{
        $("[data-active-id="+$(this).data('id')+"]").addClass('deactivated-saved-search')
      }
    })
  }
  $('[id="freq"]').change(function() {
    const $dropdown = $(this);
    if ($dropdown.val() == 'daily') {
      var msg = 'You will now receive Op-Engine emails once a day for this saved search.';
    } else if($dropdown.val() == 'weekly') {
      var msg = 'You will now receive Op-Engine emails once a week for this saved search.';
    } else if($dropdown.val() == 'never') {
      var msg = 'You will no longer receive Op-Engine emails for this saved search.';
    }
    if (inactive_filters && is_delinquent){

    }
    $.ajax({
        type: "POST",
        url: "/api/v1/preferences/email_freq/",
        data: JSON.stringify({
          'frequency': $dropdown.val(),
          'pk': $dropdown.data('id'),
          'email-type': $dropdown.data('email-type')
        }),
        dataType: 'json'
    }).fail(function() {
      M.Toast.dismissAll();
      M.toast({html: FAIL_MESSAGE});
    }).done(function(response) {
      $('[id="freq"]').each(function(){
        if (response.last_active.includes($(this).data('id'))) {
          $("[data-active-id="+$dropdown.data('id')+"]").removeClass('deactivated-saved-search')
        } else {
          $(this).val('never');
          $('select').formSelect();
          $("[data-active-id="+$(this).data('id')+"]").addClass('deactivated-saved-search')
        }
      })
      M.Toast.dismissAll();
      M.toast({html: msg})
    });
  });
  $('.delete-action').click(function() {
    return confirm('Are you sure you want to delete?');
  });
  $('.delete-saved-seach').click(function() {
    openModal('#modal-delete-saved-search')
    var pk = $(this).data('id')
    $('.yes').click(function() {
      $.ajax({
        type: "POST",
        url: "/account/preferences/delete/"+pk+"/",
        dataType: 'json'
      }).fail(function() {
        M.Toast.dismissAll();
        M.toast({html:'Failed to delete saved search'});
      }).done(function() {
        location.reload();
      });
    });
  });
}
