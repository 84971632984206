import { listingFavoriteInit } from './listing.js'
import {
  initPlaceComplete,
  numericSetup,
  US_PLACE_ID,
  CA_PLACE_ID
} from './utils.js'

let searchPage = null

const searchFormUrl = '/api/v1/marketplace/search/form/'
const marketplaceUrl = '/marketplace/'
const mapUrl = '/map/'
const dataUrl = '/map/data.json'
const genericError = 'Unable to save you search at this time'

const M = window.M
const ga = window.ga

function doSaveSearch($form, instance, successCallback) {
  return $.ajax({
    method: 'POST',
    url: '/api/v1/search-save/',
    data: $form.serialize()
  })
    .done(function (results) {
      function finished() {
        if (results.success) {
          successCallback(results)
        } else {
          M.toast({ html: genericError })
        }
      }
      if (instance) {
        instance.options.onCloseEnd = function () {
          finished()
        }
        instance.close()
      } else {
        finished()
      }
    })
    .fail(function (response) {
      if (instance) {
        instance.options.onCloseEnd = function () {
          const data = $.parseJSON(response.responseText)
          if (data.login_required) {
            window.launchJoinModal()
          } else if (data.quota) {
            window.launchUpgradePreference()
          } else {
            M.toast({ html: genericError })
          }
        }
        instance.close()
      }
    })
}

function buttonText($button, text) {
  $button.html(text + ' <span class="hide-on-med-and-down">Search</span>')
}

function savedSearchSetup($searchType, $form) {
  const $button = $('.search-save-button')
  const $id = $form.find('#id_id')
  $button.unbind('click')
  if ($id.val()) {
    buttonText($button, 'Edit')
    $button.click(function (e) {
      e.preventDefault()
      location.href = '/account/preferences/edit/' + $id.val()
    })
  } else {
    buttonText($button, 'Save')
    $button.click(function (e) {
      e.preventDefault()

      const modal = $('#modal-saved-search')
      const instance = M.Modal.getInstance(modal[0])
      instance.open()
      modal.find('.no').unbind('click')
      modal.find('.no').click(function () {
        instance.close()
      })
      modal.find('.yes').unbind('click')
      modal.find('.yes').click(function () {
        doSaveSearch($form, instance, function (results) {
          $form.find('#id_id').val(results.new_id)
          savedSearchSetup($('#id_search_type'), $form)
          $form.submit()
          M.toast({ html: 'Your seach has been saved!' })
        })
      })
    })
  }
}

function setupMoreLess() {
  if (!document.querySelectorAll('#advanced_search').length) {
    return
  }
  const $filters = $('#advanced_search')
  const $overlay = $('#advanced_search_overlay')
  const $showButton = $('#advanced-show')
  const $hideButton = $('#advanced-hide')
  $showButton.click(function (e) {
    $filters.fadeIn(250)
  })
  $hideButton.click(function (e) {
    $filters.fadeOut(250)
  })
  $overlay.click(function (e) {
    $filters.fadeOut(250)
  })
}

function updateListingContainer(callback, isFlexed = false) {
  listingFavoriteInit()
  window.partialBookLock($('input[name="account_type"][value="8"]'))

  M.Tooltip.init(document.querySelectorAll('.tooltipped'), {})
  $('#listings_container .next a').click(function () {
    if ($(this).attr('data-page')) {
      $(this).attr('disabled', true)
      searchPage = $(this).attr('data-page')
      callback()
    }
  })
  $('#listings_container .prev a').click(function () {
    if ($(this).attr('data-page')) {
      $(this).attr('disabled', true)
      searchPage = $(this).attr('data-page')
      callback()
    }
  })
  $('#listings_container li.bottom a').click(function () {
    if (isFlexed) {
      $('.panel-results').animate(
        {
          scrollTop: $('.panel-results')
        },
        250
      )
    } else {
      $('html, body').animate(
        {
          scrollTop: $('#search-form').offset().top
        },
        250
      )
    }
  })
}

function working(loading) {
  $('#error_text').addClass('hide')
  $('#loading_text').removeClass('hide')
  if (loading) {
    $('#listings_loading').fadeIn('fast')
  } else {
    $('#listings_loading').fadeOut('fast')
  }
  $('#listings_loading').removeClass('hide')
}

function formSetup(placeId) {
  if (placeId === US_PLACE_ID || placeId === CA_PLACE_ID) {
    $('.mile_radius_container').fadeOut()
  } else {
    $('.mile_radius_container').fadeIn()
  }
  $('select:not(.browser-default)').formSelect()
  $('#id_job_category').select2({
    allowClear: true,
    placeholder: "Select a category"
  });
}

function getBounds(polygons) {
  const bounds = new google.maps.LatLngBounds()
  for (var i = 0; i < polygons.length; i++) {
    for (var j = 0; j < polygons[i].length; j++) {
      const ll = new google.maps.LatLng(polygons[i][j].lat, polygons[i][j].lng)
      bounds.extend(ll)
    }
  }
  return bounds
}

window.saveSearchEdit = function (x, y) {
  const $placeId = $('#id_google_place_id')
  const map = new google.maps.Map($('#map')[0], mapOptions)
  const form = $('#preference-form')

  window.partialBookLock($('input[name="account_type"][value="8"]'))
  window.entityTypeLock($('#id_entity_type'))

  $('form').submit(function () {
    $('#submit-id-save').prop('disabled', true)
  })

  let overlays = []
  let polygons = []
  function clearOverlays() {
    overlays.map(function (o) {
      o.setMap(null)
    })
    polygons.map(function (p) {
      p.setMap(null)
    })
  }
  let mapOptions = {
    draggable: false,
    gesturehandling: 'none',
    fullscreenControl: false,
    zoom: 5,
    minZoom: 2,
    disableDefaultUI: true,
    mapTypeControlOptions: {
      mapTypeIds: []
    },
    center: new google.maps.LatLng(x, y),
    streetViewControl: false
  }

  function updateMap(borders, centroid) {
    formSetup($placeId.val())
    clearOverlays()

    let center = new google.maps.LatLng(centroid.lat, centroid.lng)
    map.panTo(center)

    if (borders) {
      borders.forEach(function (polygon) {
        const poly = new google.maps.Polygon({
          paths: polygon,
          strokeColor: '#000000',
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: '#336699',
          fillOpacity: 0.25
        })
        poly.setMap(map)
        polygons.push(poly)
        map.fitBounds(getBounds(borders))
      })
    }
  }

  function refreshData() {
    const placeId = $placeId.val()
    const mileRadius = $('#id_mile_radius').val() || '25'
    if (!placeId) {
      return
    }
    $('#map_loading').fadeIn()
    $.ajax({
      type: 'GET',
      url: '/api/v1/googlplace/border/' + placeId + '/' + mileRadius + '/',
      data: form.serialize()
    }).done(function (results) {
      updateMap(results.polygons, results.centroid)
      $('#results-count').html(results.count)
      $('#map_loading').fadeOut()
    })
  }
  form.change(function () {
    refreshData()
  })

  initPlaceComplete(
    false,
    '#id_country',
    '#id_google_place_id',
    '#id_search_query',
    '#preference-form',
    function (place) {
      const center = new google.maps.LatLng(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      )
      map.panTo(center)

      formSetup($placeId.val())
      refreshData()
    }
  )
  refreshData()
}

window.searchInit = function (startPage, show_upgrade = false) {
  show_upgrade = show_upgrade == 'True'
  searchPage = startPage
  var xhr = null

  $('.toggle_marketplace').html('<i class="fas fa-map-marker-alt"></i> View Map')
  $('.toggle_marketplace').click(function() {
    window.location = '/map/' + window.location.search
  })

  function refreshData(appendResults) {
    var $form = $('#search-form')
    // We only send the request if there is a google place id
    if (!$form.find('input[name="google_place_id"]').val()) {
      return
    }
    var ser = $form.serialize()
    var params = '?' + ser + '&listings_only=true&page=' + searchPage
    var url = dataUrl + params

    if (xhr != null) {
      xhr.abort()
    }
    working(true)
    xhr = $.ajax({
      url: url
    })
      .done(function (data) {
        var newMapUrl = mapUrl + params
        var newUrl = marketplaceUrl + params
        if (window.history.replaceState) {
          history.pushState({}, '', newUrl)
        }
        $('#button-map').attr('href', newMapUrl)
        $('#button-list').attr('href', newUrl)
        if (typeof ga !== 'undefined') {
          ga('send', 'pageview', newUrl)
        }
        working(false)
        $('#listings-count').html(data.count)
        $('#search-pager').remove()
        if (false && appendResults) {
          $('#listings_container').append(data.html)
        } else {
          $('#listings_container').html(data.html)
        }
        updateListingContainer(function () {
          refreshData(true)
        })
      })
      .fail(function (xhr, status, error) {
        if (error != 'abort') {
          $('#loading_text').addClass('hide')
          $('#error_text').removeClass('hide')
        } else {
          working(true)
        }
      })
  }

  function setup() {
    const modal = $('#modal-use-saved-search')
    const useSavedSearchinstance = M.Modal.getInstance(modal[0])
    const $useSavedSearch = $('#use-saved-search')
    const $cancelButton = $('#cancel-use-saved')
    $useSavedSearch.click(function (e) {
      useSavedSearchinstance.open()
      slEvent('Click', {
        object: 'My Searches'
      })
    })
    $cancelButton.click(function (e) {
      useSavedSearchinstance.close()
    })
    $('.clickable-row').click(function () {
      window.location = $(this).data('href')
    })

    const $form = $('#search-form')
    const $searchType = $('#id_search_type')
    const $placeId = $('#id_google_place_id')
    setupMoreLess()
    formSetup($placeId.val())
    savedSearchSetup($searchType, $form)

    window.partialBookLock($('input[name="account_type"][value="8"]'))

    initPlaceComplete(
      true,
      '#id_country',
      '#id_google_place_id',
      '#id_search_query',
      '#search-form',
      function (place) {
        const country = place.address_components.reduce((acc, val) => {
          if (val.types.filter((v) => 'country' === v).length) {
            return val.short_name
          }
        }, 'US')
        $('#id_country').val(country)
        formSetup($placeId.val())
        $('#id_search_query').blur()
        $form.submit()
      },
      false,
      true,
      true
    )
    $form.change(function () {
      $form.find('#id_id').val('')
      savedSearchSetup($searchType, $form)
      $form.submit()
    })
    $form.submit(function (event) {
      event.preventDefault()
      searchPage = 1
      refreshData(false)
      return false
    })
    $searchType.change(function () {
      $form.find('#id_id').val('')
      $.ajax({
        type: 'GET',
        url: searchFormUrl,
        data: $form.serialize()
      }).done(function (results) {
        $('#search_holder').html($(results))
        numericSetup()
        setup()
        $('#id_country').focus()
        working(false)
      })
    })
  }
  setup()
  refreshData(false)
}

window.mapSearchInit = function (
  startingPage,
  startZoom,
  locked,
  startLat,
  startLng,
  show_upgrade
) {
  const $form = $('#search-form')
  show_upgrade = show_upgrade == 'True'

  $('.toggle_marketplace').html('<i class="fas fa-list"></i> View List')
  $('.toggle_marketplace').click(function() {
    window.location = '/marketplace/' + window.location.search
  })

  ClusterOverlay.prototype = new google.maps.OverlayView()
  function ClusterOverlay(latlng, count, map) {
    this.latlng_ = latlng
    this.count_ = count
    this.map_ = map
    this.div_ = null
    this.setMap(map)
  }

  ClusterOverlay.prototype.onAdd = function () {
    var div = document.createElement('div')
    div.style.borderStyle = 'none'
    div.style.borderWidth = '0px'
    div.style.position = 'absolute'
    if (this.count_ > 0) {
      div.innerHTML = '<label class="map-overlay" >' + this.count_ + '</label>'
    }
    this.div_ = div
    var panes = this.getPanes()
    panes.overlayMouseTarget.appendChild(this.div_)

    var that = this
    google.maps.event.addDomListener(this.div_, 'mouseover', function () {
      google.maps.event.trigger(that, 'clusterhover')
    })
    google.maps.event.addDomListener(this.div_, 'click', function () {
      google.maps.event.trigger(that, 'clusterclick')
    })
  }

  ClusterOverlay.prototype.draw = function () {
    const overlayProjection = this.getProjection()
    const ll = overlayProjection.fromLatLngToDivPixel(this.latlng_)
    const width = 100
    const half_width = width / 2
    this.div_.style.left = ll.x - half_width + 'px'
    this.div_.style.top = ll.y - half_width + 'px'
    this.div_.style.width = width + 'px'
    this.div_.style.height = width + 'px'
  }

  ClusterOverlay.prototype.onRemove = function () {
    this.div_.parentNode.removeChild(this.div_)
    this.div_ = null
  }

  searchPage = startingPage
  var cacheable = false
  var mapOptions = {
    gesturehandling: 'none',
    fullscreenControl: false,
    zoomControl: false,
    zoom: startZoom,
    minZoom: 3,
    maxZoom: 9,
    center: new google.maps.LatLng(startLat, startLng),
    mapTypeControlOptions: {
      mapTypeIds: []
    },
    streetViewControl: false,
    styles: [
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          {
            color: '#f2f2f2'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            color: '#46bcec'
          },
          {
            visibility: 'on'
          }
        ]
      }
    ]
  }

  const mapElement = $('#map')[0]
  const map = new google.maps.Map(mapElement, mapOptions)
  var zoom
  var firstIdle = true
  map.addListener('idle', function () {
    console.log('map idle')
    if (cacheable && map.getZoom() == zoom) {
      return
    }
    if (firstIdle) {
      refreshMapData(false, false)
      zoom = map.getZoom()
      firstIdle = false
    }
  })

  var overlays = []
  var polygons = []
  function clearOverlays() {
    overlays.map(function (o) {
      o.setMap(null)
    })
    polygons.map(function (p) {
      p.setMap(null)
    })
  }

  function getViewport() {
    var b = map.getBounds()
    var ne = b.getNorthEast()
    var sw = b.getSouthWest()
    return sw.lat() + ',' + sw.lng() + '|' + ne.lat() + ',' + ne.lng()
  }

  var xhr = null
  function refreshMapData(listingsOnly, appendResults) {
    formSetup($('#id_google_place_id').val())
    $('#listings_loading').removeClass('hide')
    $('#listings_loading').fadeIn()
    var ser = $form.serialize()
    var viewport = getViewport()
    var params =
      '?' +
      ser +
      '&viewport=' +
      viewport +
      '&listings_only=' +
      listingsOnly +
      '&zoom=' +
      map.getZoom() +
      '&page=' +
      searchPage

    var url = dataUrl + params
    if (xhr != null) {
      xhr.abort()
    }
    xhr = $.ajax({
      url: url
    })
      .done(function (data) {
        var newMapUrl = mapUrl + params
        var newUrl = marketplaceUrl + params
        if (window.history.replaceState) {
          history.pushState({}, '', newMapUrl)
        }
        $('#button-map').attr('href', newMapUrl)
        $('#button-list').attr('href', newUrl)
        if (typeof ga !== 'undefined') {
          ga('send', 'pageview', newUrl)
        }
        cacheable = data.cacheable
        working(false)
        $('#listings-count').html(data.count)
        $('#search-pager').remove()
        if (false && appendResults) {
          $('#listings_container').append(data.html)
        } else {
          $('#listings_container').html(data.html)
        }
        updateListingContainer(function () {
          refreshMapData(true, true)
        }, true)
        if (listingsOnly) {
          return
        }
        clearOverlays()
        overlays = []

        if (data.clusters) {
          data.clusters.forEach(function (polygon) {
            const poly = new google.maps.Polygon({
              paths: polygon,
              strokeColor: '#000000',
              strokeOpacity: 0.5,
              strokeWeight: 1,
              fillColor: '#336699',
              fillOpacity: 0.25
            })
            poly.setMap(map)
            polygons.push(poly)
            map.fitBounds(getBounds(data.clusters))
          })
          const latLng = new google.maps.LatLng(
            data.centroid.lat,
            data.centroid.lng
          )
          const overlay = new ClusterOverlay(latLng, data.count, map)
          overlays.push(overlay)
        }
      })
      .fail(function (xhr, status, error) {
        if (error != 'abort') {
          $('#loading_text').addClass('hide')
          $('#error_text').removeClass('hide')
        } else {
          working(true)
        }
      })
  }
  function init() {
    const $form = $('#search-form')
    const $searchType = $('#id_search_type')
    const $placeId = $('#id_google_place_id')

    setupMoreLess()
    formSetup($placeId.val())
    savedSearchSetup($searchType, $form)

    initPlaceComplete(
      true,
      '#id_country',
      '#id_google_place_id',
      '#id_search_query',
      '#search-form',
      function (place) {
        $('#id_search_query').blur()
        $form.submit()
        map.fitBounds(place.geometry.viewport)
      },
      false,
      true,
      true
    )
    $form.change(function () {
      $form.find('#id_id').val('')
      savedSearchSetup($searchType, $form)
      $form.submit()
    })
    $form.submit(function (event) {
      event.preventDefault()
      searchPage = 1
      refreshMapData(false, false)
      return false
    })
    $searchType.change(function () {
      $form.find('#id_id').val('')
      $.ajax({
        type: 'GET',
        url: searchFormUrl,
        data: $form.serialize()
      }).done(function (results) {
        $('#search_holder').html($(results))
        numericSetup()
        init()
        working(false)
      })
    })
  }
  init()
}
