import {countryArray} from '../utils'

const locationUrl = '/api/v1/locations/'

export function locationsFetch() {
  return $.ajax({
    url: locationUrl,
    method: 'GET'
  })
}

export function locationAdd(location) {
  return $.ajax({
    url: locationUrl,
    method: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({
      locations: [location]
    })
  })
}

export function locationDelete(location) {
  return $.ajax({
    url: locationUrl,
    method: 'DELETE',
    data: JSON.stringify({
      locations: [{
        google_place_id: location.google_place_id,
        search_query: location.search_query,
      }]
    })
  })
}

export class Map {
  constructor(placeChangedCallback) {
    const mapOptions = {
      zoom: 2,
      minZoom: 2,
      maxZoom: 5,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        mapTypeIds: []
      },
      streetViewControl: false,
      center: {
        lat: 42.877742,
        lng: -97.380979
      }
    };

    this.locations = []
    this.markers = []
    this.placeChangedCallback = placeChangedCallback
    this.map = new google.maps.Map(document.querySelector('#map'), mapOptions);
    this.geocoder = new google.maps.Geocoder();

    google.maps.event.addListener(this.map, "click", (event) => {
      var latitude = event.latLng.lat();
      var longitude = event.latLng.lng();
      const query = {
        location: {
          lat: latitude,
          lng: longitude
        }
      }
      this.geocoder.geocode(query, (results, status) => {
        if (status === 'OK' && results.length) {
          const choices = results.filter((obj) => {
            return obj.types.includes('administrative_area_level_2')
                || obj.types.includes('locality')
          })
          if (choices.length) {
            this.placeChangedCallback(choices[0])
          }
        }
      })
    })
  }

  refit = () => {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach((m) => {
      bounds.extend(m.getPosition())
    })
    this.map.fitBounds(bounds);
  }

  add (location) {
    this.locations.push(location)
    this.markers.push(new google.maps.Marker({
      position: location.center,
      map: this.map,
      title: location.search_query
    }))
    this.refit()
  }

  clear() {
    this.markers.forEach((m) => {
      m.setMap(null)
    })
    this.markers = []
    this.locations = []
  }
}

export function placeComplete(placeIdSelector, inputSelector, placeChangedCallback) {
  var service = new google.maps.places.AutocompleteService();
  var placeService = new google.maps.places.PlacesService(document.createElement('div'));

  const $placeId = $(placeIdSelector)
  const $input = $(inputSelector);

  function setupSearch() {
    function placePrediction(val, predictionCallback) {
      service.getPlacePredictions({
        input: val,
        offset: val.length,
        types: [ '(regions)' ],
        componentRestrictions: {
          'country': countryArray
        }
      }, predictionCallback);
    }

    function placeGuess(results, status) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        if (results.length > 0) {
          var guess = results[0];
          $input.val(guess.description);
          $placeId.val(guess.place_id);
          placeLookup(guess.place_id, placeChangedCallback)
        }
      }
    }
    function placeLookup(place_id, callback) {
      placeService.getDetails({
        placeId: place_id
      }, function(place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (callback) {
            callback(place);
          }
        }
      });
    }
    $(document).on("keypress", ":input:not(textarea):not([type=submit])", function(event) {
      if (event.keyCode == 13) {
        event.preventDefault();
        if ($input.val().length > 3) {
          $input.blur()
        }
      }
    });
    $.ui.typeahead.prototype._renderItem = function(ul, item) {
      return $( "<li>" )
        .attr("data-place-id", item.place_id)
        .attr("data-value", item.value)
        .append($( "<div>" ).text( item.label ))
        .appendTo( ul );
    }
    $.ui.typeahead.prototype._renderMenu =  function(ul, items) {
      var that = this;
      $.each(items, function(index, item) {
        that._renderItemData(ul, item);
      });
      // Append powered by google
      $('<li class=\"powered-by\">')
        .append($("<div>").text('Powered by Google'))
        .appendTo(ul);
    }
    $input.typeahead({
      minLength: 1,
      delay: 100,
      source: function(request, response) {
        placePrediction(request.term, function(predictions) {
          // clear current place id
          $placeId.val('')
          const list = (predictions || []).map((val) => {
            return {
              label:val.description,
              value:val.description,
              place_id:val.place_id
            }
          })
          response(list)
        })
      },
      select: function(event, ui) {
        if (ui.item.place_id) {
          const place_id = ui.item.place_id
          $placeId.val(place_id);
          placeLookup(place_id, placeChangedCallback)
        }
      },
    })
    var blurTimeout = null;
    $input.blur(function() {
      var that = $(this);
      if (blurTimeout) {
        clearTimeout(blurTimeout);
        blurTimeout = null;
      }
      blurTimeout = setTimeout(() => {
        if (that.val() && !$placeId.val()) {
          placePrediction($input.val(), placeGuess);
        }
      }, 250);
    });
  }
  setupSearch();
}

