import {initPlaceComplete} from './utils'
import {setupAjaxForm} from './auth'
import {openModal} from './utils'
import { Dropzone } from "dropzone"
import copy from 'copy-to-clipboard'


$('.delete-listing-request').click(function() {
  var pk = $(this).data('id')
  $.ajax({
      type: 'GET',
      url: '/get/reasons/form',
      data: { 'pk': pk },
  }).done(function(results) {
    var form = $(results['form_html']);
    $('#request-form').html(form);
    var modal = openModal('#modal-request-delete-listing')
    setupAjaxForm(
      '#delete-listing-request',
      "/account/profile/listings/delete/"+pk+"/",
      function() {
        $('select').formSelect();
      },
      function() {
        modal.close()
        M.Toast.dismissAll();
        M.toast({html:'Request Submitted!'});
      }
    )
  })
});

$('.report-listing').click(function() {
  var pk = $(this).data('id')
  $.ajax({
      type: 'GET',
      url: '/get/report/listing/form',
      data: { 'pk': pk },
  }).done(function(results) {
    var form = $(results['form_html']);
    $('#report-listing-request').html(form);
    var modal = openModal('#modal-report-listing')
    setupAjaxForm(
      '#report-listing-form',
      "/account/profile/listings/report/"+pk+"/",
      function() {
        $('select').formSelect();
      },
      function() {
        $('.report-column').html('<small class="right report-sent">Listing Reported</small>')
        modal.close()
        M.Toast.dismissAll();
        M.toast({html:'Report Submitted!'});
      }
    )
  })
});

function buttonsDisable() {
  const $button = $("input[type=submit]");
  $button.attr("disabled", true);
  $button.val("Submitting...");
  $('.alert-block').remove();
}

function checkOfficeSpaceInterest(focus) {
  if ($('#id_office_space_interest').find("option:selected").val() == 'yes') {
    $('#div_id_office_space_interested_description').slideDown();
    if (focus) {
      $('#id_office_space_interested_description').focus();
    }
  } else {
    $('#div_id_office_space_interested_description').slideUp();
  }
};

function checkOfficeSpaceAvail(focus) {
  if ($('#id_office_space_avail').find("option:selected").val() == 'yes') {
    $('#div_id_office_space_available_description').slideDown();
    if (focus) {
      $('#id_office_space_available_description').focus();
    }
  } else {
    $('#div_id_office_space_available_description').slideUp();
  }
};

function checkRevHistories(historicals) {
  var $container = $('#rev-container')
  let year = new Date().getFullYear() - 1
  const removeRow = (y) => {
    $('#historical-' + y).remove()
  }
  const addRow = (y, aum = '', rev = '') => {
    const html = `
      <div class="row" id="historical-${y}">
        <div class="col s2">
          <div class="input-field form-group" style="height: 3rem; display: flex; align-items: center">
            ${ y }
          </div>
          <input name="historical-year" type="hidden" value="${ y }" >
        </div>
        <div class="col s2">
          <div class="input-field form-group">
            <input name="historical-aum" class="form-control numeric-comma" type="text" placeholder="AUM" value="${aum}">
          </div>
        </div>
        <div class="col s2">
          <div class="input-field form-group">
            <input name="historical-rev" class="form-control numeric-comma" type="text" placeholder="GDC" value="${rev}">
          </div>
        </div>
      </div>
    `
    $container.append(html)
  }
  if (true || $container.length == 0) {
    const header = `
      <div class="row">
        <div class="col s12">
          <div class="input-field form-group">
            <label>Optionally provide historical data to improve the quality of your listing.</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col s2">
          <div class="input-field form-group">
          Year
          </div>
        </div>
        <div class="col s2">
          <div class="input-field form-group">
            AUM
          </div>
        </div>
        <div class="col s2">
          <div class="input-field form-group">
          GDC
          </div>
        </div>
      </div>
    `
    $container.append(header)
    if (historicals && historicals.length) {
      historicals.forEach(h => {
        addRow(h[0], h[1], h[2])
        year = h[0] - 1
      })
    } else {
      addRow(year--)
      addRow(year--)
      addRow(year--)
    }
    $('#more-historical').click(() => {
      addRow(year--)
    })
    /*
    $('#less-historical').click(() => {
      removeRow(++year)
    })
    */
  }
}

function checkChoices() {
  var choiceFields = [
    'clearing_firm',
    'custodial_options',
    'third_party_money_managers',
    'mutual_funds',
    'variable_annuities',
    'insurance_business',
    'alternative_investments',

    'crm_tech',
    'fin_planning_tech',
    'analytics_tech',
    'marketing_tech',

    'considered_joining_ria',
    'agency_management_system',

    'life_insurance_business',
    'health_insurance_business',
    'auto_insurance_business',
    'home_insurance_business',
    'commercial_insurance_business',
    'other_insurance_business',

    'services',
    'credentials'
  ];

  window.buySellLock(
    $('input[name="account_type"][value="7"]'),
    $('input[name="account_type"][value="8"]'))
  window.entityTypeLock($('#id_entity_type'))
  window.recruitLockCheck(
    $('input[name="account_type"][value="12"]'),
    $('input[name="account_type"][value="13"]'),
  )

  try {
    $('#id_firm').change(function() {
      let v = $(this).find('option:selected').text()
      let $otherField = $('#div_id_firm_other')
      if (v == 'Other' || v == 'None') {
        $otherField.slideDown()
        $('#id_firm_other').focus()
      } else {
        $otherField.slideUp()
        $('#id_firm_other').val('')
      }
    })
    $('#id_firm').change()
  } catch (e) {
    console.log(e)
  }

  $.each(choiceFields, function (index, field) {
    var otherFieldOption = $('#id_' + field + '_choices_1');
    var otherField = $('#div_id_' + field + '_other');
    var choicesField = $('#div_id_' + field + '_choices');

    otherFieldOption.parent().addClass('other-option'); // add class to color label for custom text fields

    if (field !== 'clearing_firm' && field !== 'credentials' && field !== 'services' && field !== 'custodial_options') {
      if ($('#div_id_' + field).find("option:selected").val() !== 'yes') {
        choicesField.slideUp();
      } else {
        choicesField.slideDown();
      }

      $('#id_' + field).change(function () {
        var choices = $($(this).prop('id').replace('id_', '#div_id_') + '_choices');

        if ($(this).find("option:selected").val() !== 'yes') {
          choicesField.find('input[type="checkbox"]').prop('checked', false);
          choices.slideUp();
          otherField.find('input').val('');
          otherField.slideUp();
        } else {
          choices.slideDown();
        }
      });
    }

    if (otherFieldOption.prop('checked')) {
      otherField.slideDown();
    } else {
      otherField.slideUp();
      otherField.find('input').val('');
    }

    otherFieldOption.change(function () {
      if ($('#id_' + field + '_choices_1').prop('checked')) {
        otherField.slideDown();
      } else {
        otherField.slideUp();
        otherField.find('input').val('');
      }
    });

  });
  if($('#account-payment-form').length >0) {
    $('#account-payment-form input').not($('.btn')).addClass('form-control');
    $('#account-payment-form select').not($('.btn')).addClass('form-control');
    $('#account-payment-form #submit-id-submit-form').parent().wrap('<div class="col-md-12">');
  }
}

function checkPlusFields() {
  const $plusFields = $('#plus-fields-locked')
  if ($plusFields.length) {
    $plusFields.find('.legend').html('Additional Information <span class="plus-fields-tag">Plus Feature</span>')
    const $block = $plusFields.find('.plus-fields-block')
    $block.find('.plus-fields-block').css('opacity', 0.2)
    $block.find('.plus-fields-block').css('pointer-events', 'none')
    const $div = $(`
      <div class="plus-fields-overlay">
        <div>
          <a href="/account/dashboard/billling/" taget="_blank" rel="noopener">
            Upgrade to Plus to attract more M&A and recruiting candidates by providing enhanced details about your firm
          </a>
        </div>
      </div>`
    )
    $block.append($div)
  }
}

function googleAutoComplete(){
  initPlaceComplete(
    false,
    '#id_business_country',
    '#id_google_place_id',
    '#id_business_street',
    '#account-registration-one-form',
    function(place) {
      var street_number = "";
      var street = "";
      var city = "";
      var state = "";
      var zip = "";
      for (var i = 0; i <= place.address_components.length -1; i++){
        if(place.address_components[i].types.includes('street_number')){
          street_number = place.address_components[i].long_name
        }
        else if(place.address_components[i].types.includes('route')){
          street = place.address_components[i].long_name
        }
        else if(place.address_components[i].types.includes('locality')){
          city = place.address_components[i].long_name
        }
        else if(place.address_components[i].types.includes('administrative_area_level_1')){
          state = place.address_components[i].short_name
        }
        else if(place.address_components[i].types.includes('postal_code')){
          zip = place.address_components[i].long_name
        }
      }
      if (street_number != ""){
        street = street_number + " " + street
      }
      $('#id_business_street').val(street);
      $('#id_business_city').val(city);
      $('#id_business_state').val(state);
      $('#id_business_state').select2({
        allowClear: true,
        placeholder: "Select a state"
      });
      $('#id_business_zip').val(zip);
      $('#id_job_category').select2({
        allowClear: true,
        placeholder: "Select a category"
      });
    },
    true,
    false
  )
}

window.listingAdmin = function (listingId) {
  // Admin actions
  $('.listing-deny').click(function () {
    $('.listing-deny-form').removeClass('hide')
    $('.listing-deny-form textarea').focus()
  })
  $('.listing-deny-form form').submit(function(e) {
    e.preventDefault()
    $.ajax({
      method: "POST",
      url: `/account/listing/review/denied/${listingId}/`,
      data: $(this).serialize()
    }).done(function (results) {
      location.href = '/marketplace/' + listingId
    }).fail(function(xhr, status, error) {
      M.toast({html: error })
    })
    return false
  })
}

window.listingDisplayInit = function() {
  $('.copy').on('click', function() {
    const t = $(this).data('text')
    copy(t)
    M.toast({html: `${t} copied to clipboard.` });
  })
}

window.listingInit = function (marketKey, formErrors, historicals) {
  $('#id_account_type_2').click(function() {
    $('.asking-price').removeClass('hide')
    $('.rent').removeClass('hide')
  })

  // job candidates only
  if ($('#resume_file_upload').length) {
    const dropzone = new Dropzone("#resume_file_upload", {
      url: "/account/profile/user/file/?tag=resume",
      uploadMultiple: false
    })
    dropzone.on("success", file => {
      const r = JSON.parse(file.xhr.response)
      console.log(r)
      if (r && r.response.length > 0) {
        console.log(r.response[0])
        $('#id_resume_userfile').val(r.response[0].id)
      }
    });
  }

  $('#div_id_entity_type').change(function(e) {
    if (e.target.value == 'ria') {
      $('#id_percent_commission_based').val(0)
      $('#div_id_percent_commission_based').addClass('hide')
      $('#id_percent_fee_based').val(100)
      $('#div_id_percent_fee_based').addClass('hide')
      $('#id_percent_retainer_based').val(0)
      $('#div_id_percent_retainer_based').addClass('hide')
    } else {
      $('#id_percent_commission_based').val('')
      $('#div_id_percent_commission_based').removeClass('hide')
      $('#id_percent_fee_based').val('')
      $('#div_id_percent_fee_based').removeClass('hide')
      $('#id_percent_retainer_based').val('')
      $('#div_id_percent_retainer_based').removeClass('hide')
    }
  });


  function commissionSelected (selected) {
    if (selected) {
      $('#commission_rate_min').removeClass('hide')
      $('#commission_rate_max').removeClass('hide')
      $('#compensation_min_col').addClass('s3')
      $('#compensation_min_col').removeClass('s4')
      $('#compensation_max_col').addClass('s3')
      $('#compensation_max_col').removeClass('s4')
      $('#compensation_type_col').addClass('s3')
      $('#compensation_type_col').removeClass('s4')
    } else {
      $('#commission_rate_min').addClass('hide')
      $('#commission_rate_max').addClass('hide')
      $('#compensation_min_col').addClass('s4')
      $('#compensation_min_col').removeClass('s3')
      $('#compensation_max_col').addClass('s4')
      $('#compensation_max_col').removeClass('s3')
      $('#compensation_type_col').addClass('s4')
      $('#compensation_type_col').removeClass('s3')
    }
  }

  if ($('#id_job_type').val() == 'commission') {
    commissionSelected(true)
  } else {
    commissionSelected(false)
  }

  $('#id_job_type').change(function() {
    if ($(this).val() == 'commission') {
      commissionSelected(true)
    } else {
      commissionSelected(false)
    }
  })

  numericSetup();
  $("form").submit(function(e) {
    buttonsDisable()
    window.autonumericClean();
  });
  $('.search-submit-btn').removeClass('btn-primary');

  // wrap checkboxes so that we can make multiple columns
  $('[id*=_choices]').each(function(cb_index){
    const id = $(this).attr('id')
    if (id.match(/credentials/) || id.match(/services/)) {
      return
    }
    $(this).find('.checkbox').each(function(cb_index){
      $(this).wrap('<li class="cb-long-list ' + cb_index + '" />');
    });
  });

  $('[id*=_other].control-group').not('#div_id_ratio_other').addClass('other-field');
  $('#div_id_considered_joining_ria_choices').addClass('other-field');

  $('#id_office_space_avail').on('change', function() {
    checkOfficeSpaceAvail(true);
  });
  checkOfficeSpaceAvail(false);

  $('#id_office_space_interest').on('change', function() {
    checkOfficeSpaceInterest(true);
  });
  checkOfficeSpaceInterest(false);

  checkChoices();
  checkPlusFields();
  checkRevHistories(historicals);

  var form = $('#account-registration-one-form');
  /* fix mousewheel 2 - in addition to the above, this passes on the mousewheel delta to scroll the page as the user expected */
  // http://codepen.io/pixelthing/pen/LIqsg
  $('[type="number"]').on('focus', function (e) {
    $(this).on('mousewheel.disableScroll', function (e) {
      e.preventDefault();
      var scrollTo = (e.originalEvent.wheelDelta*-1) + $(document.documentElement).scrollTop();
      $(document.documentElement).scrollTop(scrollTo);
    })
  }).on('blur', function (e) {
    $(this).off('mousewheel.disableScroll')
  });

  if (marketKey === 'insurance') {
    var strNewString = $('#div_id_account_type').html().replace(/Practice/g,'Agency');
    $('#div_id_account_type').html(strNewString);
    var strNewString = $('#div_id_account_type').html().replace(/join a practice/g,'Join an Agency');
    $('#div_id_account_type').html(strNewString);
  }

  var ratioOtherFocused = false;
  var $ratioSliders;
  var $ratioOther;
  var $totalPercent;

  function updateTotalPercent($sliders)  {
    var totalPercent = 0;
    $sliders.each(function()   {
      totalPercent += parseInt($(this).val()) || 0;
    });
    if (!ratioOtherFocused) {
      var ratioOther = parseInt($ratioOther.val());
      var diff = parseInt(totalPercent - 100) || 0;
      var ratioDiff = ratioOther - diff;
      if (ratioDiff >= 0) {
        $ratioOther.val(ratioDiff);
        totalPercent -= diff;
      } else {
        $ratioOther.val(0);
        totalPercent -= ratioOther;
      }
      if (($ratioOther).val() !== "0") {
        $ratioOther.parent().addClass('animated flash');
      }
    }
    if (totalPercent != 100) {
      $totalPercent.parent().addClass('alert-danger');
    } else {
      $totalPercent.parent().removeClass('alert-danger');
    }
    $totalPercent.html(totalPercent.toFixed(2) + '%');

    return totalPercent;
  }

  googleAutoComplete()

  $('#id_business_state').select2({
    allowClear: true,
    placeholder: "Select a state"
  });

  $("#id_firm").select2({
    allowClear: true,
    placeholder: "Select a firm"
  });

  $('#id_business_country').change(function() {
      var state = $('#id_business_state').val();
      $.ajax({
          type: 'GET',
          url: '/api/v1/meta/country/',
          data: { country: $(this).val() },
      }).done(function(results) {
        var $el = $("#id_business_state");
        $el.empty();
        $.each(results['states'], function(index, tuple) {
            $el.append($("<option></option>").attr("value", tuple[0]).text(tuple[1]));
        });
        $('#div_id_business_state label').text(results['label']);
        $('#id_business_state').val(state);
        $('#id_business_state').select2({
            allowClear: true,
            placeholder: "Select a state"
        });
      });
  });
  $('#id_business_country').change();

  // function brothers(id1, id2) {
  //   function setup(id1, id2) {
  //     $(id1).keyup(function() {
  //       var val = parseInt($(this).val() || 0) || 0;
  //       if (val >= 0 && val <= 100) {
  //         $(id2).val(100 - val)
  //       }
  //     });
  //   }
  //   setup(id1, id2);
  //   setup(id2, id1);
  // }
  // brothers('#id_percent_fee_based', '#id_percent_commission_based');
  // brothers('#id_percent_annual_premiums', '#id_percent_annual_renewals');

  $totalPercent = $('#practice_ratio_mix_total_value');
  $ratioOther = $('#id_ratio_other');
  $ratioOther.focus(function() {
    ratioOtherFocused = true;
  });


  $ratioSliders = $('.practice_ratio_mix input.sumable');
  $ratioSliders.on('blur', function() {
    if ($(this).val()==''){
      $(this).val(0)
      $(this).trigger('change')
    }
  })
  $ratioSliders.keyup(function() {
    if ($(this) == $ratioOther) {
      ratioOtherFocused = true;
    }
    updateTotalPercent($ratioSliders);
    setTimeout(function() {
      $ratioOther.parent().removeClass('animated flash');
    }, 1000);
  });
  if (formErrors) {
    ratioOtherFocused = true;
  }
  updateTotalPercent($ratioSliders);

  $('#account-registration-one-form').validator({
    feedback: {
      success: 'fa-check',
      error: 'fa-times'
    },
    focus: false
  });

  var $cpaIndv = $('.indv-v-corp .indv');
  var $cpaCorp = $('.indv-v-corp .corp')
  $cpaIndv.keyup(function() {
    if ($cpaIndv.val() < 100) {
      $cpaCorp.val(100 - $cpaIndv.val())
    } else {
      $cpaCorp.val(0)
    }
  })
  $cpaCorp.keyup(function() {
    if ($cpaCorp.val() < 100) {
      $cpaIndv.val(100 - $cpaCorp.val())
    } else {
      $cpaIndv.val(0)
    }
  })
}

export function listingFavoriteInit() {
  $('.btn-favorite').unbind('click');
  $('.btn-favorite').on('click', function(e, i) {
    if (window.isAuthenticated) {
      var that = $(this)
      var listingId = that.data('listing-id')
      var method = that.data('favorite') === true ? 'DELETE' : 'POST'
      $.ajax({
          method: method,
          url: '/api/v1/listing/' + listingId + '/star/'
      }).done(function(results) {
        if (method === 'DELETE') {
          that.find('i').removeClass('active')
          that.find('i').removeClass('is_favorite')
          that.data('favorite', false)
        } else {
          that.find('i').addClass('active')
          that.find('i').addClass('is_favorite')
          that.data('favorite', true)
        }
        that.blur()
      })
    } else {
      window.launchJoinModal()
    }
  })
  $('.btn-hide').unbind('click');
  $('.btn-hide').on('click', function(e, i) {
    if (window.isAuthenticated) {
      var that = $(this)
      var listingId = that.data('listing-id')
      var method = that.data('hidden') === true ? 'DELETE' : 'POST'
      $.ajax({
          method: method,
          url: '/api/v1/listing/' + listingId + '/hide/'
      }).done(function(results) {
        if (method === 'DELETE') {
          that.find('i').removeClass('fas')
          that.find('i').removeClass('is_hidden')
          that.data('hidden', false)
        } else {
          that.find('i').addClass('fas')
          that.find('i').addClass('is_hidden')
          that.data('hidden', true)
        }
        that.blur()
      })
    } else {
      window.launchJoinModal()
    }
  })
}

window.barsInit = function(){
  jQuery(document).ready(function(){
    $('.count').each(function () {
      var $this = $(this);
      jQuery({ Counter: 0 }).animate({ Counter: $this.attr('data-percent') }, {
        duration: 3000,
        easing: 'swing',
        step: function () {
          $this.text(Math.ceil(this.Counter));
        }
      });
    });
    $('.count-reverse').each(function () {
      var $this = $(this);
      jQuery({ Counter: 100 }).animate({ Counter: $this.attr('data-percent')-1 }, {
        duration: 3000,
        easing: 'swing',
        step: function () {
          $this.text(Math.ceil(this.Counter));
        }
      });
    });
    jQuery('.skillbar').each(function(){
      jQuery(this).find('.skillbar-bar').animate({
        width:jQuery(this).attr('data-percent')
      },3000);
    });
  });
}

window.revHistory = function (listing_id, rev_name1, rev_name2) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function get_histories() {
    var section = $('#rev-histories');
    // section.html(`<div class="m-sm">Loading...</div>`);
    $.ajax({
        type: "GET",
        url: `/api/v1/rev-histories/${ listing_id }`,
    }).done(function (results) {
      if (results.rev_history_1.length > 0 || results.rev_history_2.length > 0) {
        section.html(`
          <div id="rev-histories" class="table-flex mb-lg">
            <table class="mr-sm">
              ${
                results.rev_history_1 === 0
                ? ''
                : `<thead>
                      <tr>
                        <th scope="col">Year</th>
                        <th scope="col">`+rev_name1+`</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>` + results.rev_history_1.map(l => {
                  return `
                    <tr>
                      <td>${l[0]}</td>
                      <td>${'$'+numberWithCommas(l[1])}</td>
                      <td class="delete-rev-record ta-right" data-id="${l[2]}"><i class="fas fa-trash clickable"></i></td>
                    </tr>
                  `
                }).join('')
              }
            <table class="ml-sm">
              ${
                results.rev_history_2 === 0
                ? ''
                : `<thead>
                      <tr>
                        <th scope="col">Year</th>
                        <th scope="col">`+rev_name2+`</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>` + results.rev_history_2.map(l => {
                  return `
                    <tr>
                      <td>${l[0]}</td>
                      <td>${'$'+numberWithCommas(l[1])}</td>
                      <td class="delete-rev-record ta-right" data-id="${l[2]}"><i class="fas fa-trash clickable"></i></td>
                    </tr>
                  `
                }).join('')
              }
            </table>
          </div>`
        )
      } else {
        section.html(`<div class="m-sm">No income histories yet</div>`)
      }
      $('.delete-rev-record').click(function() {
        var id = $(this).data('id')
        $.ajax({
            type: "POST",
            url: `/api/v1/delete/rev-history/${ id }/`
        }).done(function (results) {
          if (results.success) {
            get_histories(listing_id)
          } else {
            M.Toast.dismissAll();
            M.toast({html: results.message });
          }
        })
      })
    })
  }

  $('#add-history').click(function () {
    $.ajax({
        type: "POST",
        url: `/api/v1/add/rev-history/${ listing_id }/`,
        data: {
          rev_type: $('#rev_type').val(),
          year: $('#rev_year').val(),
          amount: $('#rev_amount').val()
        }
    }).done(function (results) {
      if (results.success) {
        get_histories(listing_id)
      } else {
        M.Toast.dismissAll();
        M.toast({html: results.message });
      }
    })
  })
  get_histories(listing_id)
}
