export const US_PLACE_ID = 'ChIJCzYy5IS16lQRQrfeQ5K5Oxw'
export const CA_PLACE_ID = 'ChIJ2WrMN9MDDUsRpY9Doiq3aJk'
export const DEF_PLACE_ID = 'ChIJSx6SrQ9T2YARed8V_f0hOg0'
export const MATERIALIZE_SMALL_WIDTH = 600

export const countryArray = [ 'US', 'CA' ];

export const rev_color_array = ['#325F96', '#6787B2', '#94A8BE']
export const business_mix_color_array = ['#E25668', '#E256AE', '#D056E3', '#8A57E2', '#5768E2', '#56AEE2', '#56E3CF']


var suggestedSearches = []

$(".see-savings-calc").click(function(){
  var element_height = $(".chalice-container").height() +10;
  $("html, body").animate({ scrollTop: element_height }, 500);
});

function fetchSuggestions() {
  $.ajax({
    url: '/api/v1/search-suggestions/',
    contentType: 'application/json',
    success: function(data) {
      if (data.ideas) {
        suggestedSearches = data.ideas
      } else {
        suggestedSearches = []
      }
    },
    error: function() {
      suggestedSearches = []
    }
  });
}

export function openModal(modal_id){
  var modal = M.Modal.getInstance(document.querySelector(modal_id))
  modal.open()
  return modal
}

export function initPlaceComplete(
    submitOnBlur, countryIdSelector, placeIdSelector, inputSelector, formSelector,
    placeChangedCallback, address=false, onblur=true, includeSuggestions=false) {
  var service = new google.maps.places.AutocompleteService();
  var placeService = new google.maps.places.PlacesService(document.createElement('div'));
  var googleGuess = '(regions)';
  const $placeId = $(placeIdSelector)
  const $age = $('#id_published_age')
  if (address == true){
    googleGuess = 'address'
  }

  if (includeSuggestions) {
    fetchSuggestions()
  }

  function setupSearch() {
    const $input = $(inputSelector);
    var $country = $(countryIdSelector);
    var $search_type = $('#id_search_type');

    function placePrediction(val, predictionCallback) {
      service.getPlacePredictions({
        input: val,
        offset: val.length,
        types: [ googleGuess ],
        componentRestrictions: {
          'country': countryArray
        }
      }, predictionCallback);
    }

    function placeGuess(results, status) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        if (results.length > 0) {
          var guess = results[0];
          $input.val(guess.description);
          $placeId.val(guess.place_id);
          placeLookup(guess.place_id, placeChangedCallback)
        }
      }
      if (submitOnBlur) {
        $(formSelector).submit();
      }
    }
    function placeLookup(place_id, callback) {
      placeService.getDetails({
        placeId: place_id
      }, function(place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (callback) {
            callback(place);
          }
        }
      });
    }
    $(document).on("keypress", ":input:not(textarea):not([type=submit])", function(event) {
      if (event.keyCode == 13) {
        event.preventDefault();
      }
    });
    $.ui.typeahead.prototype._renderItem = function(ul, item) {
      const label = $("<div>").text(item.label)
      if (item.chip) {
        label.append(
          $("<span>").addClass("right plus-exclusive")
            .append($("<strong>").text(item.chip)))
      }
      return $("<li>")
        .attr("data-place-id", item.place_id)
        .attr("data-value", item.value)
        .append(label)
        .appendTo(ul);
    }
    $.ui.typeahead.prototype._renderMenu =  function(ul, items) {
      function section(iconClass, title) {
        const $icon = $("<i>").addClass(iconClass)
        $('<li class=\"ignore autocomplete-section\">')
          .append($("<div>").html("<i class='" + iconClass + "'></i>" + title))
          .appendTo(ul);
      }
      var that = this;
      items = items.filter(function(i) { return i.label !== 'ignore' })
      if (items.length) {
        section("fas fa-search", "Matches")
        $.each(items, function(index, item) {
          that._renderItemData(ul, item);
        });
      }

      const yourSearches = []
      if (yourSearches.length) {
        section("fas fa-search", "Saved Searches")
      }
      if (suggestedSearches.length > 0) {
        section("fas fa-lightbulb", "Suggested Searches")
        $.each(suggestedSearches, function(index, item) {
          that._renderItemData(ul, item)
        })
      }

      // Append powered by google
      $('<li class=\"ignore powered-by\">')
        .append($("<div>").text('Powered by Google'))
        .appendTo(ul);
    }
    $(formSelector).submit(function(event) {
      if (!$input.val()) {
        $placeId.val('');
        return true;
      } else if (!$input.val()) {
        placePrediction($input.val(), placeGuess)
        return false;
      } else {
        return true;
      }
    });
    // Automatically show options
    $input.on('focus', function() {
      $input.typeahead("search", $input.val())
    })
    /**
     * This is hacky, but in order to get the dropdown menu to always show, we
     * put a dummy element in the source list. We can ignore this item when the
     * menu is rendered.
     */
    const defaultSource = includeSuggestions
      ? [{ label: 'ignore', value: 'ignore' }]
      : [];
    $input.typeahead({
      minLength: 0,
      minChar: 0,
      source: defaultSource,
      delay: 100,
      source: function(request, response) {
        if (request.term) {
          placePrediction(request.term, function(predictions) {
            // clear current place id
            $placeId.val('')
            const list = (predictions || []).map((val) => {
              return {
                label:val.description,
                value:val.description,
                place_id:val.place_id
              }
            })
            response(list.concat(defaultSource))
          })
        } else {
          response(defaultSource)
        }
      },
      select: function(event, ui) {
        if (ui.item.href) {
          location.href = '/marketplace/?' + ui.item.href;
        } else if (ui.item.place_id) {
          const place_id = ui.item.place_id
          $placeId.val(place_id);
          placeLookup(place_id, placeChangedCallback)
        }
      },
    })
    if (onblur) {
      var blurTimeout = null;
      $input.blur(function() {
        var that = $(this);
        if (blurTimeout) {
          clearTimeout(blurTimeout);
          blurTimeout = null;
        }
        blurTimeout = setTimeout(() => {
          if (that.val() && !$placeId.val()) {
            placePrediction($input.val(), placeGuess);
          }
        }, 250);
      });
    }
    /*
    $search_type.change(function() {
      $(formSelector).unbind('submit');
      $(formSelector).submit();
    });
    $country.change(function() {
      autocomplete.setComponentRestrictions({'country': $(this).val()});
      $input.val('');
      $input.focus();
    });
    */
  }
  setupSearch();
}


export function similarListings(url) {
  var populated = false;
  $('#similar').appear(function(e) {
    if (populated) {
      return;
    }
    $.ajax({
      url: url,
      contentType: 'text/html',
      success: function(data) {
        populated = true;
        $('#similar').html(data);
      },
      error: function() {
        populated = true;
        $('#similar').hide();
      }
    });
  });
}

export function partialBookLock(seller) {
  function partialBook(show) {
    if (show) {
      $('.partial_book_sale_div').removeClass('hide')
    } else {
      $('.partial_book_sale_div').addClass('hide')
    }
  }
  seller.change(function() {
    partialBook(seller.prop('checked'))
  });
  if (seller.prop('checked')) {
    partialBook(true)
  }
}

export function entityTypeLock(field) {
  function entityTypeCheck(val, clear) {
    if (val === 'ria') {
      $('.num_of_iars_block').removeClass('hide')
      if (clear) {
        $('#id_num_of_iars').val('')
      }
    } else {
      $('.num_of_iars_block').addClass('hide')
      if (clear) {
        $('#id_num_of_iars').val(0)
      }
    }
  }
  field.change(function() {
    entityTypeCheck(field.val(), true)
  })
  entityTypeCheck(field.val(), false)
}

export function recruitLockCheck(field1, field2) {
  function check() {
    if (field1.is(':checked') || field2.is(':checked')) {
      $('.recruiting_overview_row').removeClass('hide')
    } else {
      $('.recruiting_overview_row').addClass('hide')
    }
  }
  field1.click(function() {
    check()
  })
  field2.click(function() {
    check()
  })
  check()
}

export function buySellLock(buyer, seller) {
  var accountTypeColor = buyer.css('color');
  function checkboxChange(root, child) {
    if (root.prop('checked')) {
      child.prop('disabled', 'disabled');
      child.parent().css('color', '#dadada');
    } else {
      child.removeAttr('disabled');
      child.parent().css('color', accountTypeColor);
    }
  }
  function partialBook(show) {
    if (show) {
      $('.partial_book_sale_div').removeClass('hide')
    } else {
      $('.partial_book_sale_div').addClass('hide')
    }
  }
  function askingPrice(show) {
    if (show) {
      $('.asking_price_div').removeClass('hide')
      $('.rent_div').removeClass('hide')
    } else {
      $('.asking_price_div').addClass('hide')
      $('.rent_div').addClass('hide')
    }
  }
  function sellerFields(show) {
    toggleFields(show, '.seller_only_visibility', '.seller_required', '.seller_only_visibility_div')
  }
  function nonSellerFields(show) {
    toggleFields(show, '.nonseller_only_visibility', '.nonseller_required', '.nonseller_only_visibility_div')
  }
  function toggleFields(show, visibilityClass, requiredClass, visibilityDivClass) {
    if (show) {
      $(visibilityClass).each(function(i) {
        const $parent = $('#div_' + $(this).attr('id')).parent()
        $parent.removeClass('hide')
      })
      $(visibilityDivClass).each(function(i) {
        $(this).removeClass('hide')
      })
      $(requiredClass).each(function(i) {
        const $parent = $('#div_' + $(this).attr('id')).parent()
        $parent.find('label').append('<span class="asteriskField">*</span>')
        $(this).attr('required', 'required')
      })
    } else {
      $(visibilityClass).each(function(i) {
        const $parent = $('#div_' + $(this).attr('id')).parent()
        $parent.addClass('hide')
      })
      $(visibilityDivClass).each(function(i) {
        $(this).addClass('hide')
      })
      $(requiredClass).each(function(i) {
        const $parent = $('#div_' + $(this).attr('id')).parent()
        $parent.find('label span').remove()
        $(this).attr('required', '')
      })
    }
  }
  buyer.change(function() {
    checkboxChange($(this), seller);
    // partialBook(false)
    askingPrice(false)
    sellerFields(false)
  });
  seller.change(function() {
    checkboxChange($(this), buyer);
    // partialBook(seller.prop('checked'))
    askingPrice(seller.prop('checked'))
    sellerFields(seller.prop('checked'))
  });
  if (buyer.prop('checked')) {
    checkboxChange(buyer, seller);
    // partialBook(false)
    askingPrice(false)
    sellerFields(false)
  }
  if (seller.prop('checked')) {
    checkboxChange(seller, buyer);
    // partialBook(true)
    askingPrice(true)
    sellerFields(true)
  }
}

export function autonumericClean() {
  $('input.numeric-comma').each(function(index){
    try {
      $(this).val( $(this).autoNumeric('get') );
    } catch (e) {
      console.log(e);
    }
  });
}


export function numericSetup() {
  var numericComma = $('input.numeric-comma');
  try {
    numericComma.each(function() {
      var v = $(this).val().replace(/[, ]/g, '');
      $(this).val(v)
    });
    numericComma.autoNumeric('init', {
      'vMin': 0,
      'vMax': 9999999999999
    });
    numericComma.autoNumeric('update', {});
  } catch (e) {
    console.log(e);
  }
}

export function slEvent(eventName, params) {
  if (typeof Intercom != "undefined") {
    Intercom('trackEvent', eventName, params);
  }
  if (typeof mixpanel !== 'undefined') {
    mixpanel.track(eventName, params)
  }
}

export function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

export function showSupport(content) {
  if (content) {
    Intercom('showNewMessage', content);
  } else {
    Intercom('show');
  }
}

export function showMore() {
  if (parseInt( $('.truncate').css('height'), 10) > 120) {
    $('.read-more-container').html('<a class="btn btn-primary btn-outline btn-xs read-more" href="#">Read More</a>');

    $('.truncate').dotdotdot({
      ellipsis     : '… ',
      watch        : true,
      wrap         : 'word',
      height       : parseInt( $('.truncate').css('line-height'), 10) * 5, // this is the number of lines
      lastCharacter: {
        remove: [ ' ', ',', ';', '.', '!', '?' ],
        noEllipsis: []
      },
      after: "a.read-more"
    });

    $('a.read-more').on( 'click', function(event) {
      event.preventDefault();
      $(this).parent().prev('.truncate').trigger("destroy");
      $(this).hide();
    });
  }
}

export function gtag (data) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

export function ajaxSetup () {
  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = jQuery.trim(cookies[i]);
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  function csrfSafeMethod(method) {
      // these HTTP methods do not require CSRF protection
      return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
  }
  $.ajaxSetup({
      beforeSend: function(xhr, settings) {
          if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
              xhr.setRequestHeader("X-CSRFToken", getCookie('csrftoken'));
          }
      }
  });
}

export function createPiechartLegend(pie_chart_data, color_array, chart_id) {
  $('#' + chart_id).append("<div id='"+chart_id+"-legend' class='pie-chart-legend'></div>")
  for (var entry in pie_chart_data) {
    if (pie_chart_data[entry]) {
      var string1 = '<div class="legend-child"><div class="legend-color" style="background-color: '+color_array[0]+';"></div><div>'+entry+'</div></div>'
      $('#'+chart_id+'-legend').append(string1)
      color_array.shift()
    }
  }
}
