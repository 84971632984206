import {initPlaceComplete} from './utils'

const M = window.M;
const domains = [
  'gmail.com',
  'yahoo.com',
  'lpl.com',
  'ampf.com',
  'hotmail.com',
  'aol.com',
  'raymondjames.com',
  'comcast.net',
  'wradvisors.com',
  'ml.com',
  'nm.com',
  'msn.com',
  'ubs.com',
  'financialguide.com',
  'sbcglobal.net',
  'morganstanley.com',
  'lfg.com',
  'me.com',
  'wfafinet.com',
  'ms.com',
]

const secondLevelDomains = [
  'hotmail',
  'gmail',
  'aol',
  'yahoo',
  'icloud',
  'comcast'
]

const topLevelDomains = ['com', 'net', 'org', 'edu'];

window.slEvent = function(eventName, params) {
  if (typeof Intercom != "undefined") {
    Intercom('trackEvent', eventName, params);
  }
}

window.showSupport = function(content) {
  if (content) {
    Intercom('showNewMessage', content);
  } else {
    Intercom('show');
  }
}

function getModal(id) {
  return M.Modal.getInstance(document.querySelector(id))
}

function getTabs(id) {
  return M.Tabs.getInstance(document.querySelector(id))
}

window.setupUserInfo = function() {
  const form = $('#personal-info')
  const f = form.validator({
    feedback: {
      success: 'fa-check',
      error: 'fa-times'
    },
    focus: false,
    html: true,
    custom: {
      requiresone: function($el) {
        var name = $el.attr('name')
        var count = $('input[name="'+name+'"]:checked').length;
        if (count > 0) {
            $('input[name="'+name+'"]').off('.bs.validator')
                .removeData(['bs.validator.errors', 'bs.validator.deferred', 'bs.validator.previous']);
        } else {
          return 'Required';
        }
      },
    }
  });
  window.buySellLock($('#id_buyer_interest'), $('#id_seller_interest'))
}

window.launchCcModal = function() {
  getModal('#modal-cc').open();
  setTimeout(function(){
    $('#ccModal #id_stripe_token').focus()
  }, 750);
}

function setModalMessage(message) {
  if (message) {
    $('#modal-auth .message col').html(message)
    $('#modal-auth .message').removeClass('hide')
  } else {
    $('#modal-auth .message').addClass('hide')
  }
}

window.launchLoginModal = function(message) {
  setModalMessage(message)
  const modal = getModal('#modal-auth')
  const tabs = getTabs('#modal-auth .tabs')
  modal.options.onOpenEnd = () => {
    tabs.select('login')
  }
  modal.open()
  setTimeout(function(){
    $('#modal-auth #login #login-email').focus()
  }, 750);
}

window.launchJoinModal = function(message) {
  setModalMessage(message)
  const modal = getModal('#modal-auth');
  // const tabs = getTabs('#modal-auth .tabs')
  // modal.options.onOpenEnd = () => {
  //   tabs.select('join')
  // }
  modal.open()
  setTimeout(function(){
    $('#modal-auth #id_first_name').focus()
  }, 750);
}

window.launchApplyModal = function(message) {
  const modal = getModal('#modal-apply');
  modal.open()
  setTimeout(function(){
    $('#modal-apply #id_first_name').focus()
  }, 750);
}

window.launchContactWithCheck = function() {
  if ((window.listingCount && window.listingCount > 0) || window.listingType === 'job') {
    launchContactModal(true)
  } else {
    launchContactModal(false);
  }
}

window.toggleContactForm = function(showForm) {
  if (showForm) {
    $('#modal-contact .message-pane').show()
    $('#modal-contact .info-pane').hide()
  } else {
    $('#modal-contact .message-pane').hide()
    $('#modal-contact .info-pane').show()
  }
}

window.launchContactModal = function(showForm) {
  const modal = getModal('#modal-contact')
  if ($('#modal-contact .tabs').length) {
    const tabs = getTabs('#modal-contact .tabs')
    modal.options.onOpenEnd = () => {
      tabs.select('resume_existing')
    }
  }
  modal.open()
  toggleContactForm(showForm)
}

window.launchBenefits = function(message) {
  // setModalMessage(message)
  getModal('#dialog-membership-benefits').open();
}

window.launchUpgradePreference = function() {
  getModal('#membershipBenefitsFiltersModal').open();
}

export function setupAuth() {
  setupAjaxForm('#basic-login', '/api/v1/login/', null, function(response) {
    if (location.pathname == '/home' || location.pathname == '/pricing') {
      location.href = '/account/'
    } else {
      try {
        const url = new URL(location.href)
        if (url.searchParams.get('next')) {
          location.href = url.searchParams.get('next')
        } else {
          location.reload()
        }
      } catch (e) {
        // Unable to read URL...go to account homepage
        location.href = '/account/'
      }
    }
  });
  setupAjaxForm('#basic-registration', '/api/v1/register/', function(form) {
    let trustEmail = false
    const googlePlaceId = '#modal-auth #id_google_place_id';
    const f = form.validator({
      feedback: {
        success: 'fa-check',
        error: 'fa-times'
      },
      focus: false,
      html: true,
      custom: {
        placeidpop: function($el) {
          if (($(googlePlaceId).val() || '').length <= 0) {
            return 'Please select a valid location';
          }
        },
        emailvalidate: function($el) {
          const d = $.Deferred()
          if ($el.is(':focus') || trustEmail) {
            d.resolve()
          } else {
            $el.mailcheck({
              domains: domains,
              secondLevelDomains: secondLevelDomains,
              topLevelDomains: topLevelDomains,
              suggested: function(element, suggestion) {
                var yes = $('<a />').attr('href', 'javascript:void(0)')
                  .text(suggestion.full + '?')
                  .on('click', function() {
                    $el.val(suggestion.full)
                    form.validator('validate')
                  })
                const div1 = $('<div />')
                  .append('Did you mean ')
                  .append(yes)
                var no = $('<a />').attr('href', 'javascript:void(0)')
                  .text('No, this is the correct email.')
                  .on('click', function() {
                    trustEmail = true
                    form.validator('validate')
                  })
                const div2 = $('<div class="nested" />').append(no)
                d.resolve($('<div />')
                  .append(div1)
                  .append(div2)
                )
              },
              empty: function(element) {
                d.resolve()
              }
            });
          }
          return d
        },
        requiresone: function($el) {
          var name = $el.attr('name')
          var count = $('input[name="'+name+'"]:checked').length;
          if (count > 0) {
              $('input[name="'+name+'"]').off('.bs.validator')
                  .removeData(['bs.validator.errors', 'bs.validator.deferred', 'bs.validator.previous']);
          } else {
            return 'Required';
          }
        },
      }
    });
    if (($(googlePlaceId).val() || '').length <= 0) {
      initPlaceComplete(
        false,
        '#modal-auth #id_country_of_interest',
        googlePlaceId,
        '#modal-auth #id_search_query',
        '#modal-auth #basic-registration',
        function() {
          f.validator('validate');
        })
    }
    window.buySellLock($('#id_buyer_interest'), $('#id_seller_interest'))
  }, function(data) {
    if (data.next_url) {
      location.href = data.next_url
    } else {
      location.href = '/join/complete/'
    }
  });
  setupAjaxForm('#basic-forgot', '/api/v1/forgot/', null, function(data) {
    location.href = data['next_url']
  });

  setupAjaxForm('#basic-apply', '/api/v1/apply/', function(form) {
    let trustEmail = false
    const googlePlaceId = '#modal-auth #id_google_place_id';
    const f = form.validator({
      feedback: {
        success: 'fa-check',
        error: 'fa-times'
      },
      focus: false,
      html: true,
      custom: {
        placeidpop: function($el) {
          if (($(googlePlaceId).val() || '').length <= 0) {
            return 'Please select a valid location';
          }
        },
        emailvalidate: function($el) {
          const d = $.Deferred()
          if ($el.is(':focus') || trustEmail) {
            d.resolve()
          } else {
            $el.mailcheck({
              domains: domains,
              secondLevelDomains: secondLevelDomains,
              topLevelDomains: topLevelDomains,
              suggested: function(element, suggestion) {
                var yes = $('<a />').attr('href', 'javascript:void(0)')
                  .text(suggestion.full + '?')
                  .on('click', function() {
                    $el.val(suggestion.full)
                    form.validator('validate')
                  })
                const div1 = $('<div />')
                  .append('Did you mean ')
                  .append(yes)
                var no = $('<a />').attr('href', 'javascript:void(0)')
                  .text('No, this is the correct email.')
                  .on('click', function() {
                    trustEmail = true
                    form.validator('validate')
                  })
                const div2 = $('<div class="nested" />').append(no)
                d.resolve($('<div />')
                  .append(div1)
                  .append(div2)
                )
              },
              empty: function(element) {
                d.resolve()
              }
            });
          }
          return d
        },
        requiresone: function($el) {
          var name = $el.attr('name')
          var count = $('input[name="'+name+'"]:checked').length;
          if (count > 0) {
              $('input[name="'+name+'"]').off('.bs.validator')
                  .removeData(['bs.validator.errors', 'bs.validator.deferred', 'bs.validator.previous']);
          } else {
            return 'Required';
          }
        },
      }
    });
    if (($(googlePlaceId).val() || '').length <= 0) {
      initPlaceComplete(
        false,
        '#modal-apply #id_country_of_interest',
        googlePlaceId,
        '#modal-apply #id_search_query',
        '#modal-apply #basic-registration',
        function() {
          f.validator('validate');
        })
    }
  }, function(data) {
    if (data.next_url) {
      location.href = data.next_url
    } else {
      location.href = '/join/complete/'
    }
  });

  $('.login-button').click(function() {
    const { pathname}  = window.location
    let url = '/login'
    if (pathname !== '/' && pathname !== '/about-us' && pathname !== '/pricing') {
      url += '?next=' + encodeURIComponent(window.location.href)
    }
    location.href = url
    slEvent('login-button', { })
  });

  $('#modal-auth a[href="#login"]').on('click', function() {
    setTimeout(function() {
      $('#modal-auth #login #login-email').focus()
    }, 250);
    slEvent('login-button', { })
  });
  $('#modal-auth a[href="#join"]').on('click', function() {
    setTimeout(function() {
      $('#modal-auth #join #id_first_name').focus()
    }, 250);
    slEvent('join-button', { })
  });
  $('#modal-auth a[href="#forgot"]').on('click', function() {
    setTimeout(function() {
      $('#modal-auth #forgot #forgot-email').focus()
    }, 250);
    slEvent('forgot-button', { })
  });
}

function bootstrapValidator(form) {
  form.validator({
    feedback: {
      success: 'fa-check',
      error: 'fa-times'
    },
    focus: false
  });
}

export function setupAjaxForm(id, url, setupCallback, redirectCallback) {
  var $form = $(id);
  var $button = $form.find('input[type=submit]');
  if (setupCallback) {
    setupCallback($form);
  } else {
    bootstrapValidator($form);
  }
  $(id).submit(function() {
    $button.prop('disabled', true);
    // This will be rebuilt when we call setupAjaxForm recursively
    $form.validator('destroy')
    $.ajax({
      url: url,
      type: "POST",
      // data: $form.serialize(),
      data: new FormData($form[0]),
      processData: false,
      contentType: false,
      success: function(data) {
        if (!(data['success'])) {
          $form.replaceWith(data['form_html']);
          setupAjaxForm(id, url, setupCallback, redirectCallback);
          $button.prop('disabled', false);
        } else {
          redirectCallback(data)
        }
      },
      error: function (e) {
        $form.find('#server-error').remove();
        $form.prepend($('<div id="server-error" class="alert alert-block alert-danger"><ul><li>We are having issues connecting to the server. Please try again later.</li></ul></div>'));
        $button.prop('disabled', false);
      }
    });
    return false;
  });
}

window.loginInit = function() {
  var $form = $('#account-lookup')
  $('#email').focus()
  $('#email').keypress(function(event) {
    if (event.keyCode == 13 || event.which == 13) {
      $form.submit()
    }
  });
  $form.submit(function() {
    console.log('submit')
    return true
  })
}

export function commonInit() {
  if ($('#contactMessage').length > 0) {
    var $form = $('#contact-seller-form')
    bootstrapValidator($form)
    $('#contactMessage form input[type="submit"]').removeClass('btn-primary').addClass('btn-success')
    $form.submit(function() {
      var button = $(this).find('input[type="submit"]')
      button.val('Sending message...please wait');
      button.attr('disabled', true);
      return true;
    });
  }
  $('.guide-dismiss').click(function() {
    $.ajax({
        url: '/api/v1/guide/dismiss',
        type: "POST",
        success: function(data) {
          $('.account-progress').fadeOut('slow')
        },
        error: function (e) {
          $('.account-progress').fadeOut('slow')
        }
    });
  });
  $('.auction-end').each(function(index) {
      var then = $(this),
          date = moment(new Date(then.attr('data-date'))),
          updateMoment = function() {
              then.html(date.fromNow(true));
          };
      updateMoment();
      setInterval(updateMoment, 60000);
  });
}
