import * as M from 'materialize-css';
import {setupAuth, commonInit} from './auth';
import * as utils from './utils';
import {listingFavoriteInit} from './listing';
// import Raven from 'raven-js';
import './home';
import './conversations';
import './email_settings';
import './dtc';
import './search';
import './billing';
import './profileviews';
import './locations/app';
import './news-events';
import './about-us.js'
import jstz from 'jstimezonedetect';
import 'lazysizes';
import {rev_color_array, business_mix_color_array, MATERIALIZE_SMALL_WIDTH} from './utils'
// import {business_mix_color_array} from '.u'

utils.ajaxSetup();

window.M = M;
window.initPlaceComplete = utils.initPlaceComplete
window.similarListings = utils.similarListings
window.buySellLock = utils.buySellLock
window.recruitLockCheck = utils.recruitLockCheck
window.partialBookLock = utils.partialBookLock
window.entityTypeLock = utils.entityTypeLock
window.autonumericClean = utils.autonumericClean
window.createPiechartLegend = utils.createPiechartLegend
window.numericSetup  = utils.numericSetup
window.slEvent = utils.slEvent
window.slugify = utils.slugify
window.showSupport = utils.showSupport
window.showMore = utils.showMore
window.gtag = utils.gtag
window.materialize_small_width = MATERIALIZE_SMALL_WIDTH
window.rev_color_array = rev_color_array
window.business_mix_color_array = business_mix_color_array

window.gmapsBlock = function() {
  if (typeof gmapsHook !== 'undefined') {
    gmapsHook();
  }
  if (!window.isAuthenticated) {
    setupAuth()
  }
  commonInit()
  listingFavoriteInit();
}

function checkScroll(selector, percent) {
  const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  const navBar = $(selector)
  if (navBar.offset().top > height * percent) {
    // navBar.addClass('navbar-opaque')
  } else {
    // navBar.removeClass('navbar-opaque')
  }
}

function setupScroll(selector, percent) {
  if (document.querySelector(selector)) {
    $(window).scroll(function() {
      checkScroll(selector, percent)
    });
    checkScroll(selector, percent)
  }
}

document.addEventListener('DOMContentLoaded', function() {
  setupScroll('.home nav', .75);
  setupScroll('.pricing-page nav', .12);

  M.Carousel.init(document.querySelector('.carousel-strategic-partners'), {
    fullWidth: true,
    padding: 50,
    indicators: false,
    numVisible: 5,
  });

  M.Carousel.init(document.querySelector('.carousel-success'), {
    fullWidth: true,
    padding: 50,
    indicators: false,
    numVisible: 1,
  });

   M.Carousel.init(document.querySelector('.carousel-success-videos'), {
    fullWidth: true,
    padding: 50,
    indicators: false,
    numVisible: 3,
  });

   M.Carousel.init(document.querySelector('.carousel-testimonials'), {
    fullWidth: true,
    padding: 50,
    indicators: false,
    numVisible: 3,
  });

  M.Carousel.init(document.querySelector('.blog-articles'), {
    fullWidth: true,
    padding: 50,
    indicators: false,
    numVisible: 3,
  });

  M.Sidenav.init(document.querySelectorAll('.sidenav'), {});
  M.Tabs.init(document.querySelectorAll('#search-block .tabs'), {});
  M.Tabs.init(document.querySelectorAll('.form-signin .tabs'), { });
  if ($('.tabs-resume').length) {
    M.Tabs.init(document.querySelector('.tabs-resume .tabs'), {});
  }
  M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});

  M.Modal.init(document.querySelectorAll('.modal'), {
    onOpenStart: function() {
      $('#modal-scroll').show();
    },
    onCloseEnd: function() {
      $('#modal-scroll').hide();
    }
  })
  M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'), {
    coverTrigger: false,
    closeOnClick: true,
    constrainWidth: false,
    /* hover: true */
  });
  M.Dropdown.init(document.querySelectorAll('.dropdown-trigger-hover'), {
    coverTrigger: false,
    closeOnClick: true,
    constrainWidth: false,
    hover: true
  });

  const today = new Date();
  const yearArray = [1900, today.getFullYear()]
  $('.datepicker').each(function() {
    let defaultDate = $(this).val()
      ? window.moment($(this).val(), 'YYYY-MM-DD').toDate()
      : null
    const instance = M.Datepicker.init($(this)[0], {
      format: 'yyyy-mm-dd',
      yearRange: yearArray,
      maxDate: today,
      max: true,
      defaultDate: defaultDate,
      setDefaultDate: true,
    });
    $(this).on('focus', () => {
      instance.open();
    });
  })


  M.FormSelect.init(document.querySelectorAll('select'), { });
  M.Collapsible.init(document.querySelectorAll('.collapsible'), {
    accordion: false
  });

  M.Collapsible.init(document.querySelectorAll('.accordion'), {
    accordion: true
  });

  numericSetup();
  $("form").submit(function(e) {
    window.autonumericClean();
  });

  $('.apply-button').click(function() {
    if (window.isAuthenticated) {
      location.href = '/account/'
    } else {
      launchApplyModal();
    }
  });

  $('.join-button').click(function() {
    if (window.isAuthenticated) {
      location.href = '/account/'
    } else {
      // launchJoinModal();
      location.href = '/join?next=' + encodeURIComponent(window.location.pathname)
      slEvent('join-button', { })
    }
  });

  $('.contact-button').click(function() {
    launchContactWithCheck();
  });

  $('.contact-button-force').click(function() {
    toggleContactForm(true)
  });

  $('.activate-tos').click(function() {
    const m = M.Modal.getInstance(document.querySelector('#modal-tos'));
    m.open();
  });

  $('.activate-pp').click(function() {
    const m = M.Modal.getInstance(document.querySelector('#modal-privacy-policy'));
    m.open();
  });
  if (typeof domLoaded !== "undefined") {
    domLoaded()
  }
  setupVideoModal()

  try {
    if (localStorage) {
      const tz = jstz.determine().name()
      if (tz !== localStorage.getItem('timezone')) {
        $.ajax({
          method: 'POST',
          url: '/api/v1/timezone/',
          data: { timezone: tz }
        }).done(function (results) {
          try {
            localStorage.setItem('timezone', tz)
          } catch (e) {
            // Ignore if we can't set the item
          }
        })
      }
    }
  } catch (e) {
    // TODO: log this
  }
});


function setupVideoModal() {
  $("body").find('[data-target="videoModal"]').click(function () {
    const modal = $('#videoModal')
    const instance = M.Modal.getInstance(modal[0])
    instance.open()

    var videoSRC = $(this).attr("data-embed-video"),
        videoSRCparams = "https://www.youtube.com/embed/" + videoSRC + "?autoplay=1&showinfo=0&wmode=opaque&rel=0";
    modal.find('iframe').attr('src', videoSRCparams);
    modal.find('button.close').click(function () {
      modal.find('iframe').attr('src', videoSRC);
    });
    instance.options.onCloseStart = function() {
      modal.find('iframe').attr('src', '');
    }
  });
}


$('#sidenav-resources').click(function() {
  $('#resources-sublist').removeClass('hide')
})

$('#sidenav-profiles').click(function() {
  $('#profiles-sublist').removeClass('hide')
})

$('#sidenav-markets').click(function() {
  $('#markets-sublist').removeClass('hide')
})

$('#sidenav-settings').click(function() {
  $('#settings-sublist').removeClass('hide')
})

$('#sidenav-billing').click(function() {
  $('#billing-sublist').removeClass('hide')
})

$('#sidenav-pricing').click(function() {
  $('#pricing-sublist').removeClass('hide')
})

$('#sidenav-tools').click(function() {
  $('#tools-sublist').removeClass('hide')
})
