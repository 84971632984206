
export function partnerCarousel() {
  var elems = document.querySelector('.carousel-strategic-partners');
  if (elems) {
    var instance = M.Carousel.getInstance(elems);
    $('.next-arrow').click(function() {
      instance.next()
    })
    $('.prev-arrow').click(function() {
      instance.prev()
    })
  }
}

export function successVideoCarousel() {
  var elems2 = document.querySelector('.carousel-success-videos');
  if (elems2) {
    var instance2 = M.Carousel.getInstance(elems2);
    $('.next-arrow2').click(function() {
      instance2.next()
    })
    $('.prev-arrow2').click(function() {
      instance2.prev()
    })
  }
}

export function testimonialCarousel() {
  var elems2 = document.querySelector('.carousel-testimonials');
  if (elems2) {
    var instance2 = M.Carousel.getInstance(elems2);
    $('.next-arrow2').click(function() {
      instance2.next()
    })
    $('.prev-arrow2').click(function() {
      instance2.prev()
    })
  }
}

export function blogCarousel() {
  var elems3 = document.querySelector('.blog-articles');
  if (elems3)
  {
    var instance3 = M.Carousel.getInstance(elems3);
    $('.blog-next-arrow2').click(function() {
      instance3.next()
    })
    $('.blog-prev-arrow2').click(function() {
      instance3.prev()
    })
  }
}

export function successCarousel() {
  var elems1 = document.querySelector('.carousel-success');
  if (elems1) {
    var instance1 = M.Carousel.getInstance(elems1);
    $('.next-arrow1').click(function() {
      instance1.next()
    })
    $('.prev-arrow1').click(function() {
      instance1.prev()
    })
  }
}
