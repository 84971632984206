import {MATERIALIZE_SMALL_WIDTH} from './utils'
const engagementActionUrl = '/account/engagements/action/'
const engagementsUrl = '/account/engagements/';
const engagementPartials = '/account/engagements-partials/';
const sessionUrl = '/api/v1/session/profile-select/';
let firstLoad = true;

const M = window.M;
const moment = window.moment;

function report(conversationId, reason, success, error) {
  $.ajax({
    type: 'POST',
    dataType: 'json',
    contentType : 'application/json',
    url: '/api/v1/conversation/' + conversationId + '/report/' + reason + '/',
    success: success,
    error: error
  });
}

function unreport(conversationId, success, error) {
  $.ajax({
    type: 'DELETE',
    dataType: 'json',
    contentType : 'application/json',
    url: '/api/v1/conversation/' + conversationId + '/report/',
    success: success,
    error: error
  });
}

function fetchMore(partials, sort_by, nextPage, firstLoad, finishedCalback, search="") {
  $.ajax({
      type: 'GET',
      url: partials + '?page=' + nextPage +'&sort_by=' + sort_by + '&search=' + search,
      contentType : 'application/json',
  }).done(function(results) {
    if (finishedCalback) {
      finishedCalback();
    }
    if (firstLoad) {
      $('#conversation-container').html(results);
    } else {
      $('#conversation-container').append(results);
    }
    if ($('.convo-select').length) {
      $('.convo-select-all').removeClass('hide');
    }
    afterNewData();

    // Make it recursive brosee
    $('.load-more').unbind('click');
    $('.load-more').on('click', function() {
      $(this).html('Loading...');
      $(this).prop('disabled', true);
      fetchMore(partials, sort_by, $('.load-more').data('next-page') || '1', false, () => {
        $('.load-more-row').remove();
      });
    })
  });
}


function showActions(callback) {
  if ($('.convo-select:checked').length > 0) {
    if ($('.action-pills').is(":hidden")) {
      $('.action-pills').fadeIn(100, callback);
    }
  } else {
    $('.action-pills').fadeOut(100, callback);
  }
}

function engagementActions(button, engagementUrl, idCallback, finishedCalback) {
  var action = button.data('action');
  var ids = idCallback()
  var data = {
    "function": action,
    "conversations": ids
  };
  function execute(message) {
    let newData = data
    if (message) {
      newData.trashResponse = message;
    }
    $.ajax({
        url: engagementUrl,
        type: 'POST',
        contentType : 'application/json',
        data: JSON.stringify(newData)
    }).done(function() {
        finishedCalback(action);
    });
  }
  if ("delete" === action) {
    var verb = "Delete";
    var targetNoun = "engagement";
    if (ids.length > 1) {
      targetNoun = "engagements";
    }
    const selector = '#modal-are-you-sure-delete'
    const instance = M.Modal.getInstance(document.querySelector(selector));
    const modal = $(selector);
    modal.find("h4").html("<strong>" + verb + "</strong> " + targetNoun + "?");
    modal.find(".modal-message").html("Are you sure you want to " + verb + " <strong>" + ids.length + "</strong> " + targetNoun + "?");
    modal.find(".yes").html("Yes! " + verb + " my " + targetNoun);
    modal.find('.yes').click(function() {
      execute($('#reason_to_delete').val());
    });
    modal.find('.no').click(function() {
      instance.close()
    });
    instance.open();
  } else {
    execute();
  }
}

function afterNewData() {
  $('.convo-select').unbind('click');
  $('.convo-select').on('click', function() {
    showActions(function() { });
  });
  M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
  $('.star-button').unbind('click');
  $('.star-button').on('click', function() {
    let $button = $(this)
    let action= $button.data('action')
    engagementActions($button, engagementActionUrl, function() {
      return [$button.data('id')]
    }, function() {
      if (action == 'star') {
        $button.removeClass('notstarred').removeClass('far').addClass('starred').addClass('fas')
        $button.data('action', 'unstar')
      } else {
        $button.removeClass('starred').removeClass('fas').addClass('starred').addClass('far')
        $button.data('action', 'star')
      }
    })
  })
}

window.conversationsInit = function(status, conversationId) {
  const statusUrl = engagementsUrl + status + '/';
  const partials = engagementPartials + status + '/';
  const $selectAll = $('.convo-select-all');
  $selectAll.click(function() {
    let newText = '';
    if ($(this).data('selected')) {
      $('.convo-select').prop('checked', false);
      $(this).data('selected', false)
      newText = 'Select All';
    } else {
      $('.convo-select').prop('checked', true);
      $(this).data('selected', true)
      newText = 'Deselect All';
    }
    showActions(function() {
      $selectAll.html(newText);
    });
  });
  $('.action').click(function() {
    engagementActions($(this), engagementActionUrl, function() {
      return $('.convo-select:checked').map(function() {
        return $(this).data('id')
      }).get();
    }, function() {
      location.reload();
    })
  });
  $('#session_profiles').change(function() {
    $.ajax({
      type: 'POST',
      url: sessionUrl,
      data: JSON.stringify({
        'index': $(this).val()
      }),
      contentType : 'application/json',
    }).done(function() {
      location.reload();
    });
  });

  $('#sort_by').change(function() {
    var val = $(this).val();
    var search = $('#search_for').val();
    fetchMore(partials, val, '1', true, null, search)
  })

  $('#search_btn').click(function() {
    var val = $('#sort_by').val();
    var search = $('#search_for').val();
    fetchMore(partials, val, '1', true, null, search)
  })

  $("#search_for").keyup(function(event) {
    if (event.keyCode === 13) {
        $("#search_btn").click();
    }
    else if (event.keyCode === 8 || event.keyCode === 46) {
      var search = $('#search_for').val();
      if (search === "") {
        $("#search_btn").click();
      }
    }
  });

  $('.msg-created').each(function(){
    const then = $(this);
    const date = moment(new Date(then.attr('data-date')))
    const updateMoment = () => {
      then.html(date.fromNow());
    };
    updateMoment();
    setInterval(updateMoment, 60000);
  });

  $('.prefill').click(function() {
    $('#id_message').val($(this).data('message'))
  })

  $('.unreport-message').click(function() {
    unreport($(this).data('conversation-id'), function() {
      location.reload()
    }, function() {
    });
  })
  $('.report-message').click(function() {
    const selector = '#modal-report-message';
    const reportModal = $(selector)
    const reportInstance = M.Modal.getInstance(document.querySelector(selector));
    const conversationId = $(this).data('conversation-id');
    reportInstance.open()
    reportModal.find('.response-button').each(function() {
      var button = $(this);
      button.off().click(function() {
        report(conversationId, button.data('id'), function() {
          location.href = statusUrl;
        }, function() {
          // Error...
        });
        reportInstance.close()
      });
    });
    return false;
  });
  $('.details-action').click(function() {
    engagementActions($(this), engagementActionUrl, function() {
      return [ conversationId ];
    }, function(action) {
      if (action == "delete") {
        location.href = statusUrl;
      } else {
        location.reload();
      }
    })
  });
  function messageToggle(id) {
    if ($('.inline[data-msg-id*=' + id + ']').hasClass('hide')) {
      messageVisibility(id, false, true);
    } else {
      messageVisibility(id, true, true);
    }
  }
  function messageVisibility(id, expand) {
    if (expand) {
      $('.message2[data-msg-id*=' + id + ']').addClass('message2-expanded').removeClass('message2-collapsed');
      $('.inline[data-msg-id*=' + id + ']').addClass('hide')
      $('.formatted[data-msg-id*=' + id + ']').removeClass('hide')
      $('[data-caret-id*=' + id + ']').removeClass('fa-caret-down').addClass('fa-caret-up')
    } else {
      $('.message2[data-msg-id*=' + id + ']').removeClass('message2-expanded').addClass('message2-collapsed');
      $('.inline[data-msg-id*=' + id + ']').removeClass('hide')
      $('.formatted[data-msg-id*=' + id + ']').addClass('hide')
      $('[data-caret-id*=' + id + ']').removeClass('fa-caret-up').addClass('fa-caret-down')
    }
  }
  $('.message2-header').click(function() {
    messageToggle($(this).data('msg-id'));
  });
  $('.inline').click(function() {
    messageVisibility($(this).data('msg-id'), true)
  });
  $('.message2').each(function() {
    var isUnread = $(this).data('unread');
    var isLast = $(this).data('islast');
    messageVisibility($(this).data('msg-id'), isUnread || isLast)
  });
	const cnt = $('.message2-collapsed:not(:last)').length
	if (cnt > 0) {
		$('.hidden-group').removeClass('hide')
		$('.message2-collapsed:not(:last)').addClass('hide')
		$('.hidden-group span').text(cnt)
		$('.hidden-group').click(function() {
			$('.message2-collapsed').removeClass('hide')
			$(this).addClass('hide')
		})
	}

  var updateGroup = function(el) {
    if (el.data('status') === 'open') {
      el.find('.status-label').text('Open');
      $('.status-open').hide();
      $('.status-closed').show();
    } else if (el.data('status') === 'closed') {
      el.find('.status-label').text('Closed');
      $('.status-open').show();
      $('.status-closed').hide();
      $('.status-group a.btn').removeClass('btn-info');
      $('.status-group a.btn').addClass('btn-danger');
    }
  }
  $('.status-group').each(function() {
    updateGroup($(this));
  });
  $('.status-group').click(function() {
    var that = $(this);
    var fullConversation = that.data('is-full-conversation').match(/true/i);
    var change = function(action, reason_id) {
      var data = {'status': action};
      if (reason_id) {
        data['reason_id'] = reason_id;
      }
      $.ajax({
        type: 'POST',
        dataType: 'json',
        contentType : 'application/json',
        url: '/account/engagements/' + that.data('id') + '/status/',
        data: JSON.stringify(data),
        success: function(){
          that.data('status', action);
          location.reload();
        },
        error: function() {
          M.toast({html: 'Unable to change the status of the conversation.'})
        }
      });
    }
    that.find('.status-button').click(function() {
      const selector = '#modal-why';
      const modal = $(selector)
      const modalInstance = M.Modal.getInstance(document.querySelector(selector))

      var button = $(this);
      if (button.data('action') === 'closed') {
        modal.find('.response-button').each(function() {
          var closeButton = $(this);
          closeButton.off().click(function() {
            change('closed', closeButton.data('id'));
            modalInstance.close()
          });
        });
        var noReasonBlock = modal.find('div.no-reason-block');
        modal.find('.no-reason').off().click(function() {
          change('closed');
          modalInstance.close();
        });
        modalInstance.open();
        fullConversation ? noReasonBlock.show() : noReasonBlock.hide();
      } else {
        change($(this).data('action'));
      }
    });
    updateGroup(that);
  });

  if ($('#conversation-container').length) {
    fetchMore(partials, '', '1', true, null);
  }
  var onDropdownClose = function() {
    $('#inbox-logo').toggleClass('fa-sort-up fa-sort-down');
  }
  var onDropdownOpen = function() {
    $('#inbox-logo').toggleClass('fa-sort-down fa-sort-up');
  }
  var options = {
    'onCloseStart': onDropdownClose,
    'onOpenStart': onDropdownOpen,
    'constrainWidth': false,
    'coverTrigger': false,
  }
  var elems = document.querySelectorAll('.dropdown-trigger1');
  var instances = M.Dropdown.init(elems, options);
  $(window).resize(function() {
    if ($(window).width() > MATERIALIZE_SMALL_WIDTH) {
      $('.dropdown-trigger1').dropdown('close');
    }
  })

  $('#edit-message').click(function(event) {
    $('#edit-form').prop('disabled', false);
  });
  $('#edit-form').click(function(event) {
      $('#edit-form').html("Processing... <i class='fa fa-spinner fa-spin'></i>");
      $('#edit-form').prop('disabled', true);
      var message = $('#edit-message').val();
      var convoid = $('.formatted').data('convo-id');
      var messageid = $('.edit-convo').data('msg-id');
      $.ajax({
        type: "POST",
        url: '/api/v1/conversation/edit/',
        data: {
          'message': message,
          'convoid': convoid,
          'messageid': messageid
        },
        dataType: 'json'
      }).done(function (results) {
        $('#edit-form').html("<i class='material-icons'>edit</i><span class='hide-on-med-and-down'>Update</span>");
        $('#edit-form').prop('disabled', false);
        M.toast({html: 'Update Successful'});
        location.reload();
      }).fail(function () {
        $('#edit-form').html("<i class='material-icons'>edit</i><span class='hide-on-med-and-down'>Update</span>");
        $('#edit-form').prop('disabled', false);
        M.toast({html: 'Unable to make changes at this time'});
      })
  });
}

