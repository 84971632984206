function sanitize_calculation_inputs() {
  $('.numeric-comma').autoNumeric('init', {
    'mDec': 0,
    'vMin': 0,
    'vMax': 9999999999999
  });
  // loop over all currency fields look for $ and remove it
  $('input.numeric-comma').each(function(){
    $(this).val( $(this).autoNumeric('get') );

    if ($(this).val()[0] === '$' ) {
      $(this).val( $(this).val().substring(1) );
    }
  });
}

function get_advisor_inputs() {
  sanitize_calculation_inputs();
  return {
    trailing_gdc_12_month: $('input.trailing_gdc_12_month').val(),
    aum_for_sale: $('input.aum_for_sale').val(),
    num_of_clients: $('input.num_of_clients').val(),
    percent_fee_based: $('input.percent_fee_based').val(),
    avg_client_age: $('input.avg_client_age').val(),
    term_years: $('#term_years').val()
  };
}

function get_insurance_inputs() {
  sanitize_calculation_inputs();
  return {
    agr: $('input.agr').val(),
    pc_percent: $('input.pc_percent').val(),
    life_health_percent: $('input.life_health_percent').val(),
    benefits_percent: $('input.benefits_percent').val(),
    term_years: $('#term_years').val()
  };
}

window.myDtcInit = function() {
  $('.fa-trash').click(function(e){
    var button = $('#'+(e.target.id))
    $('#'+(e.target.id) + '-are-you-sure').removeClass('hide')
    button.addClass('hide')
  })
  $('.no-trash').click(function(e){
    var id = e.target.id
    var lead_id = id.slice(0, id.length-3)
    $('#'+lead_id+'-trash-are-you-sure').addClass('hide')
    $('#'+lead_id+'-trash').removeClass('hide')
  })
}

const notifyParent = () => {
  window.parent.postMessage({
    dtcHeight: document.body.scrollHeight + 'px'
  }, '*')
}

window.dtcAdvisorInit = function(listing, dtcUrl, defaultTerm, isPaying, run_calculations=false) {
  return dtcInit(listing, dtcUrl, defaultTerm, isPaying, get_advisor_inputs, run_calculations)
}

window.dtcInsuranceInit = function(listing, dtcUrl, defaultTerm, isPaying, run_calculations=false) {
  return dtcInit(listing, dtcUrl, defaultTerm, isPaying, get_insurance_inputs, run_calculations)
}

window.dtcInit = function(listing, dtcUrl, defaultTerm, isPaying, get_calculation_inputs, run_calculations) {
  var insurance = false
  if (get_calculation_inputs == get_insurance_inputs){
    insurance = true
  }

  // if listing was passed lets make sure the initial data gets commas
  if (listing) {
    update_autonumeric();
  } else {
    listing = '';
  }

  var $calculation_input = $('input.calculation_input');
  var $submit_practice_data = $('#submit_practice_data');
  var down_payment_recommended, down_payment_min, down_payment_max;

  var $infoInput = $('input.advisor_info_input')
  var $infoFields = $('.advisor-info input[type="text"]');

  var disabledPracticeDataMessage =
    '<div class="text-center disabled-fields disabled-text"><h6 class="text-danger">Please Fill In Information Correctly to Activate Calculator</h6></div>';
  var disabledSubmitPracticeDataMessage =
    '<div class="text-center disabled-submit disabled-text"><h6 class="text-danger">Please Fill In All Practice Data to Calculate</h6></div>';
  var percentagesMustBeOneHundred =
    '<div class="text-center disabled-submit disabled-text"><h6 class="text-danger">Percentages Must Be Equal to 100%</h6></div>';
  var $dtcBanner = $('.dtc-banner');

  function hideDealTermsCalculation() {
    $('#deal_terms_calculation').hide();
  }

  function showDealTermsCalculation() {
    $('#deal_terms_calculation').slideDown();
  }

  function enablePracticeFields() {
    $('.disabled-fields').remove();
    $('.practice-info').css('opacity', 1);
    $('.practice-info input').prop('disabled', '');
    enableSubmitPracticeData();
  }

  function disablePracticeFields() {
    if ($('.advisor-info .disabled-fields').length) {
    } else {
      disableSubmitPracticeData();
      $('.advisor-info').prepend(disabledPracticeDataMessage);
      $('.practice-info').css('opacity', .25);
      $('.practice-info input').prop('disabled', 'disabled');
    }
  }

  function show_practice_fields() {
    var show = false;
    $infoFields.each(function() {
      if($.trim($(this).val()).length === 0) {
        show = false;
        return show;
      } else {
        show = true;
      }
    });
    return show;
  }

  function enableSubmitPracticeData() {
    if ( show_practice_fields() === true ) {
      $('.disabled-submit').remove();
      $('.calculator-controls').fadeIn();
    }
  }

  function disableSubmitPracticeData() {
    if ($('.practice-info .disabled-submit').length) {
    } else {
      if (insurance && parseInt($('#id_pc_percent').val()) + parseInt($('#id_life_health_percent').val()) + parseInt($('#id_benefits_percent').val()) != 100){
          $('.practice-info').prepend(percentagesMustBeOneHundred);
        }
      $('.practice-info').prepend(disabledSubmitPracticeDataMessage);
      $('.calculator-controls').fadeOut();
      hideDealTermsCalculation();
    }
  }

  function show_submit_practice_data() {
    var show = false;
    if (!$('#id_contact_lead_type_buy').is(':checked')
      && !$('#id_contact_lead_type_sell').is(':checked')
      && !$('#id_contact_lead_type_merge').is(':checked')) {
      show = false
      return
    }
    $('input.calculation_input').each(function() {
      if ($.trim($(this).val()).length === 0) {
        show = false;
        return show;
      } else {
        if (insurance){
          if (parseInt($('#id_pc_percent').val()) + parseInt($('#id_life_health_percent').val()) + parseInt($('#id_benefits_percent').val()) == 100){
            show = true;
          } else {
            show = false
          }
        } else {
          show = true
        }
      }
    });
    return show;
  }

  function firstInputFocus(){
    $('#id_contact_first_name').focus();
  }

  if (show_practice_fields()) {
    enablePracticeFields();
  } else {
    disablePracticeFields();
  }

  if (show_submit_practice_data()) {
    enableSubmitPracticeData();
  } else {
    disableSubmitPracticeData();
  }

  $infoInput.on('keyup', function() {
    if (show_practice_fields()) {
      enablePracticeFields();
    } else {
      disablePracticeFields();
    }
  });

  $calculation_input.on('keyup', function() {
    if (show_submit_practice_data()) {
      enableSubmitPracticeData();
    } else {
      disableSubmitPracticeData();
    }
  });

  $calculation_input.on('change', function() {
    if (show_submit_practice_data()) {
      enableSubmitPracticeData();
    } else {
      disableSubmitPracticeData();
    }
  });

  $submit_practice_data.on('click', function(e) {
    e.preventDefault();
    $submit_practice_data.prop('disabled', true);

    get_calculations().done(function(result) {
      if (result.success) {
        setup_sliders();
        update_autonumeric();
        showDealTermsCalculation();
      }
      $submit_practice_data.prop('disabled', false);
    });
  });

  $dtcBanner.on('click', function(e){
    firstInputFocus();
  });

  //---------------------------------------//

  function update_autonumeric() {
    var $numericComma = $('.numeric-comma');
    var $sliderDownPaymentMinVal = $('#slider_down_payment .minVal');
    var $sliderDownPaymentMaxVal = $('#slider_down_payment .maxVal');

    $numericComma.autoNumeric('init', {
      'mDec': 0,
      'vMin': 0,
      'vMax': 9999999999999
    });

    $numericComma.autoNumeric('update');
    $sliderDownPaymentMinVal.val('$' + $sliderDownPaymentMinVal.val());
    $sliderDownPaymentMaxVal.val('$' + $sliderDownPaymentMaxVal.val());
  }

  function setup_sliders() {
    setup_slider_term();
  }

  function setup_slider_term() {
    var defaultVal = defaultTerm;
    const $slider = $('#slider_term');
    const $term_years = $('#term_years');
    $slider.val(defaultVal);
    const update = () => {
      $term_years.val($slider.val());
      get_calculations().done(function(result) {
        if (result.success) {
          update_autonumeric();
        }
      });
    }
    $slider.on('change', update);
    $slider.on('input', update);
    /*
    if (!isPaying) {
      $slider.prop('disabled', true);
      $slider.click(function() {});
      $term_years.prop("disabled", true);
    }
    */
    $term_years.val($slider.val());
    $term_years.on('keyup', function() {
      var value = $(this).val();
      $slider.val(parseInt(value));
      get_calculations();
    });
  }

  function sanitize_contact_inputs() {
    //console.log('CHECK CONTACT INPUTS & WARN');
  }

  function get_contact_lead_type(){
    var inputElements = $('input[name=contact_lead_type]');
    var leadType = {};

    for(var i=0; i < inputElements.length; ++i){
      if(inputElements[i].checked){
        leadType[inputElements[i].value] = true;
      } else {
        leadType[inputElements[i].value] = false;
      }
    }
    return leadType;
  }

  function get_contact_inputs(){
    sanitize_contact_inputs();
    var contact_lead_type = get_contact_lead_type();
    var first_name = $('input.contact_first_name').val();
    var last_name = $('input.contact_last_name').val();
    var email = $('input.contact_email').val();
    var phone = $('input.contact_phone').val();
    var contact_lead_type_buy = contact_lead_type['buy'];
    var contact_lead_type_sell = contact_lead_type['sell'];
    var contact_lead_type_merge = contact_lead_type['merge'];
    var lead_source = (window.location != window.parent.location) ? document.referrer: document.location;
    var listing_id = listing

    return {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      lead_type_buy: contact_lead_type_buy,
      lead_type_sell: contact_lead_type_sell,
      lead_type_merge: contact_lead_type_merge,
      lead_source: lead_source['origin'],
      listing_id: listing_id
    };
  }

  if ($('#save-results-btn')) {
    $('#save-results-btn').click(function() {
      get_calculations(true)
    })
  }

  function get_calculations(save_calcs=false) {
    var inputs = get_contact_inputs();
    var term_calc_inputs = get_calculation_inputs();
    Object.keys(term_calc_inputs).forEach(function(key) {
      inputs[key] = term_calc_inputs[key]
    })
    inputs['save_calcs'] = save_calcs
    return $.get(dtcUrl, inputs).done(function(result) {
      if (!result.success) {
        if (result.failure_reason === 'request_login') {
          launchJoinModal('Please subscribe to use the Deal Terms Calculator');
        } else {
          launchBenefits();
        }
        return result
      }

      const data = result.data;
      for (var i in data) {
        var obj = data[i];
        var key = Object.keys(data[i])[0];
        var value = obj[key];
        if (value) {
          $('.' + key).show();
          $('#' + key).val(value);
          if (key === 'down_payment_min') {
            down_payment_min = value;
          }
          if (key === 'down_payment_max') {
            down_payment_max = value;
          }
          if (key === 'down_payment_recommended') {
            down_payment_recommended = value;
          }
        } else {
          $('.' + key).hide();
        }
      }
      update_autonumeric();

      const newHash = '#submitted';
      if (window.history.replaceState) {
        history.pushState(null, null, newHash);
      } else {
        location.hash = newHash
      }
      gtag({
        'event': 'DTC-Submitted'
      })
      return result;
    });
  }

  if (run_calculations) {
    $submit_practice_data.prop('disabled', true);
    get_calculations(true).done(function(result) {
      if (result.success) {
        setup_sliders();
        update_autonumeric();
        showDealTermsCalculation();
      }
      $submit_practice_data.prop('disabled', false);
    });
  }

  if (window.parent) {
    notifyParent()
    window.addEventListener('resize', () => {
      notifyParent()
    });
    M.Collapsible.init(document.querySelectorAll('.collapsible'), {
      onOpenEnd: () => notifyParent(),
      onCloseEnd: () => notifyParent(),
      accordion: false
    });
  } else {
    M.Collapsible.init(document.querySelectorAll('.collapsible'), {
      accordion: false
    });
  }
};

window.bizEquityInit = function(bizequity_url) {
  $('#button-id-valuation-submit').click(function() {
    $('#valuation-errors').addClass('hide')
    $('#button-id-valuation-submit').html("Submitting... <i class='fa fa-spinner fa-spin'></i>");
    $('#button-id-valuation-submit').prop("disabled", true);
    var data = $('#biz-equity-form').serialize().split("&");
    var obj={};
    for(var key in data)
    {
      obj[data[key].split("=")[0]] = data[key].split("=")[1];
    }
    var comma = '%2C';
    var re = new RegExp(comma, 'g');
    obj.revenue = obj.revenue.replace(re, '');
    obj.income = obj.income.replace(re,'');
    for (var item in obj){
      if (obj[item] == ""){
        $('#valuation-errors').removeClass('hide')
        $('#valuation-errors').html('Please fill in all fields.')
        $('#button-id-valuation-submit').prop("disabled", false);
        $('#button-id-valuation-submit').html("Get Valuation");
        return
      }
    }
    $.ajax({
      method: 'GET',
      url: '/account/business/valuation/submit',
      data: obj
    }).done(function(results) {
      $('#button-id-valuation-submit').prop("disabled", false);
      $('#button-id-valuation-submit').html("Get Valuation");
      $('.valuation-results').removeClass('hide')
      $('.valuation-range').text(results.low_range + ' — ' + results.high_range)
      $('#full-valuation').attr("href", results.full_report_url)
      $('#login-for-valuation').attr("href", '/join/?next=/account/business/valuation/quick/'+results.valuation_id+'/')
      $('#sign-up-for-valuation').attr("href", '/join/?next=/account/business/valuation/quick/'+results.valuation_id+'/#join')
      // $('.purchaged-content').removeClass('hide')
    }).fail(function(xhr, status, error) {
      $('#button-id-valuation-submit').prop("disabled", false);
      $('#button-id-valuation-submit').html("Get Valuation");
      console.log('fail')
    })
  });
}

