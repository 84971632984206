import { partnerCarousel, testimonialCarousel } from './carousel'

window.homeInit = function() {
  $('#search-form .tabs li').on('click', 'a', function(e) {
    const tabVal = $(this).attr('data-value')
    const tabName = $(this).attr('data-name')
    const placeholder = 'Search ' + tabName + ' near...'

    $('#search-form #search_type').val(tabVal);
    $('#search-form #location_query').focus()
    $('#search-form #location_query').attr('placeholder', placeholder);
  })

  $('.home #search-form').submit(function() {
    return true
  })
  $('.sl-enterprise-home #search-form').submit(function() {
    return true
  })

  testimonialCarousel()
  partnerCarousel()

  initPlaceComplete(
    false,
    '.home #search-form #id_country',
    '.home #search-form #google_place_id',
    '.home #search-form #location_query',
    '.home #search-form',
    function () {
      $('#search-form #search_query').val(
        $('#search-form #location_query').val());
    })
}
