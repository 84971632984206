import {MATERIALIZE_SMALL_WIDTH} from './utils'

const M = window.M;
const moment = window.moment;

window.profileViewsInit = function (page) {
  let container = $('#profile-views-container')
  $('#view_profiles').change(function() {
    container.html('<h4 class="ta-center w-100 pt-xl"><i class="fa fa-spinner fa-spin"></i></h4>')
    fetchviews(null, 1, $(this).val())
  })
  function setUpDates() {
    $('.view-when').each(function() {
        const then = $(this);
        const date = moment(new Date(then.attr('data-date')))
        const updateMoment = () => {
          then.html(date.fromNow());
        };
        updateMoment();
        setInterval(updateMoment, 60000);
      });
    }

  function setupButtons() {
    container.find('a.next').click(function() {
      if ($(this).attr('data-page')) {
        $(this).html("Next <i class='fa fa-spinner fa-spin'></i>");
        $(this).attr("disabled", true);
        fetchviews($(this), $(this).attr('data-page'));
      }
    });
    container.find('a.prev').click(function() {
      if ($(this).attr('data-page')) {
        $(this).html("Prev <i class='fa fa-spinner fa-spin'></i>");
        $(this).attr("disabled", true);
        fetchviews($(this), $(this).attr('data-page'));
      }
    });
  }
  function fetchviews(button, page, listing_id) {
    let url ='/account/profile/views/paged/?page=' + page
    if (listing_id) {
      url += '&listing_id=' + listing_id
    }
    $.ajax({
      url: url,
      success: function(data) {
        if (button) {
          button.attr("disabled", false);
        }
        container.html(data.html)
        setupButtons()
        setUpDates()
        if (window.history.replaceState) {
          history.pushState({}, '', location.pathname + '?page=' + page);
        }
      },
      error: function(data) {
        $content.html('<h2>Unable to retrieve profile views at this time. Please try again later.</h2>');
      }
    });
  }
  fetchviews(null, 1, null)
}

