
window.newsEventsInit = function() {
  var elems = document.querySelector('.tabs-section .tabs');
  var instance = M.Tabs.init(elems, {});

  $("#press-tab").on('click', function() {
    console.log('hello')
    $(".press-section").removeClass('hide')
    $(".news-section").addClass('hide')
    $(".events-section").addClass('hide')
    $(".success-section").addClass('hide')
  })

  $("#news-tab").on('click', function() {
    $(".news-section").removeClass('hide')
    $(".press-section").addClass('hide')
    $(".events-section").addClass('hide')
    $(".success-section").addClass('hide')
  })

  $("#events-tab").on('click', function() {
    $(".events-section").removeClass('hide')
    $(".news-section").addClass('hide')
    $(".press-section").addClass('hide')
    $(".success-section").addClass('hide')
  })

  $("#success-tab").on('click', function() {
    $(".success-section").removeClass('hide')
    $(".news-section").addClass('hide')
    $(".press-section").addClass('hide')
    $(".events-section").addClass('hide')
  })
}
