import {
  Map,
  placeComplete,
  locationsFetch,
  locationAdd,
  locationDelete
} from './locationUtils'
import classNames from 'classnames'

const M = window.M
const React = window.React
const ReactDOM = window.ReactDOM

const trashClass = "fas fa-trash fa-1"
const loadingClass = "fa fa-spinner fa-spin fa-1"

class Location extends React.PureComponent {
  state = {
    icon: trashClass
  }
  onDelete = () => {
    const {location, parent} = this.props
    this.setState({
      icon: loadingClass
    })
    locationDelete(location)
      .done(() => {
        return parent.refresh()
      }).fail(() => {
        M.toast({
          html: 'Unable to delete this location'
        })
      }).always(() => {
        this.setState({
          icon: "fas fa-trash"
        })
      })
  }

  render() {
    const {location} = this.props
    return (
      <div className="left">
        <div className="chip">
          <strong className="p-sm">{location.search_query}</strong>
          <button className="btn btn-flat p-none" type="button" onClick={this.onDelete}>
            <i className={this.state.icon}></i>
          </button>
        </div>
      </div>
    )
  }
}

class LocationList extends React.PureComponent {
  render() {
    return this.props.locations.map((loc, idx) => (
      <Location key={idx} parent={this.props.parent} location={loc} />
    ))
  }
}

class NewLocation extends React.PureComponent {
  reset = () => {
    this.$input.val('')
    this.$placeId.val('')
    this.$input.focus()
  }

  componentDidMount() {
    const {parent} = this.props
    const that = this
    this.$input = $('#locations #search_query')
    this.$placeId = $('#locations #google_place_id')
    placeComplete('#locations #google_place_id', '#locations #search_query', (place) => {
      locationAdd({
        google_place_id: place.place_id,
        search_query: this.$input.val()
      }).done((results) => {
        parent.refresh()
          .done(function() {
            that.reset()
          })
      }).fail((results) => {
        M.toast({ html: 'Unable to save this location' })
        that.reset()
      })
    })
  }

  render() {
    return (
      <div className="input-field">
        <label for='search_query'>I am interested in...</label>
        <input
          autoFocus
          id='search_query'
          type='text'
          placeholder='Enter a city or state/province' />
        <input id='google_place_id' type='hidden' />
      </div>
    )
  }
}

class AreasOfInterest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      locations: []
    }
    this.map = new Map((place) => {
      locationAdd({
        google_place_id: place.place_id,
        search_query: place.formatted_address
      }).done((results) => {
        this.refresh()
      }).fail((results) => {
        M.toast({ html: 'Unable to save this location' })
      })
    })
  }

  refresh = () => {
    return locationsFetch()
      .done((data) => {
        this.setState({
          locations: data.locations
        })
        this.map.clear()
        this.state.locations.forEach((loc) => {
          this.map.add(loc)
        })
      })
      .fail(() => {
        M.toast({
          html: 'Unable to fetch interests'
        })
      })
  }

  componentDidMount() {
    this.refresh()
  }

  render() {
    return (
      <form id="locations">
        <div className="row">
          <div className="col s12">
            <NewLocation parent={this} />
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <LocationList parent={this} locations={this.state.locations} />
          </div>
        </div>
        <div className="row">
          <div className="col s12 text-center">
            <a className={classNames('btn locations-continue', {'disabled': this.state.locations.length == 0})}
              href="/account/">Continue</a>
          </div>
        </div>
      </form>
    )
  }
}

window.areaOfInterestInit = function(selector) {
  ReactDOM.render(<AreasOfInterest />, document.querySelector(selector))
}
